import { FormattedMessage } from 'react-intl'
import icon1 from '../.././../../../assets/Dashboard/Overview/Ovpage/Guides/icon1.png'
import icon2 from '../.././../../../assets/Dashboard/Overview/Ovpage/Guides/icon2.png'
import icon3 from '../.././../../../assets/Dashboard/Overview/Ovpage/Guides/icon3.png'

export const guidesData = [
    {
        id: 1,
        icon: icon1,
        title: <FormattedMessage id='gd-t1' defaultMessage='Product Tour'></FormattedMessage>,
        text: <FormattedMessage id='gd-tx1' defaultMessage='Start Host Design tour. The easiest way to get
        design feedback right on the design itself.'></FormattedMessage>,
        to: '/'
    },
    {
        id: 2,
        icon: icon2,
        title: <FormattedMessage id='gd-t2' defaultMessage='Usability Testing'></FormattedMessage>,
        text: <FormattedMessage id='gd-tx2' defaultMessage='Analyze how users interact with your interfaces
        so you can tweak and improve your designs.'></FormattedMessage>,
        to: '/'
    },
    {
        id: 3,
        icon: icon3,
        title: <FormattedMessage id='gd-t3' defaultMessage='Best Practices Guide'></FormattedMessage>,
        text: <FormattedMessage id='gd-tx3' defaultMessage='Learn how you can use Host Design the best way.'></FormattedMessage>,
        to: '/'
    },
]