import React from 'react'
import './Tasks.scss'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { BsGridFill, BsInfoCircle } from 'react-icons/bs'
import { FiActivity } from 'react-icons/fi'
import { GoCalendar } from 'react-icons/go'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


const Tasks = () => {
    const data = [
        { date: '2022-03-05', value: 5 },
        { date: '2022-03-10', value: 10 },
        { date: '2022-03-15', value: 15 },
        { date: '2022-03-20', value: 10 },
        { date: '2022-03-25', value: 5 },
        { date: '2022-03-30', value: 15 },

        // ...and so on, up to the last 30 days of data


    ];

    const tickFormatter = (tick) => {
        const date = new Date(tick);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        return `${day} ${month}`;
    };
    return (
        <div className='ov-tasks'>
            <div className="act-top flex-b">
                <div className="act-left flex">
                    <p><FormattedMessage id='tsk-t' defaultMessage='Tasks Completion Rate'></FormattedMessage></p>
                    <Link to='/'><FormattedMessage id='tsk-link' defaultMessage='View analytics '></FormattedMessage></Link>
                </div>

                <div className="act-right flex">
                    <div className="act-filter flex-b">
                        <GoCalendar />
                        <p>Last 30 Days</p>
                        <MdKeyboardArrowDown />
                    </div>
                </div>

            </div>


            <div className="tasks-chart">
                <ResponsiveContainer width="97%" height="100%">

                    <LineChart data={data}>
                        <XAxis dataKey="date" tickFormatter={tickFormatter} ticks={data.filter((_, i) => i % 5 === 0).map(d => d.date)} />
                        <YAxis ticks={[5, 10, 15, 20, 25, 30]} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Line type="linear" dataKey="value" stroke="#396DFF" />
                    </LineChart>
                </ResponsiveContainer>

            </div>
        </div>
    )
}

export default Tasks