import React from 'react'
import './Drop1.scss'


const Drop1 = ({ handlePhoneSelect, setDrop }) => {

    const handleItemClick = (phone) => {
        handlePhoneSelect(phone);
        setDrop(false);
    };

    return (
        <div className='drop1' data-aos='fade-up' data-aos-duration='400'>
            <div className="dr1-items flex-c">
                <p onClick={() => handleItemClick('iPhone 13, 12 Pro Max')}>iPhone 13, 12 Pro Max</p>
                <p onClick={() => handleItemClick('iPhone 13, 12 Pro, 12')}>iPhone 13, 12 Pro, 12</p>
                <p onClick={() => handleItemClick('iPhone 13 Mini')}>iPhone 13 Mini</p>
                <p onClick={() => handleItemClick('iPhone XR, XS Max, 11 Pro')}>iPhone XR, XS Max, 11 Pro</p>
                <p onClick={() => handleItemClick('iPhone 8 Plus')}>iPhone 8 Plus</p>
                <p onClick={() => handleItemClick('iPhone 8, SE')}>iPhone 8, SE</p>

            </div>
        </div>
    )
}

export default Drop1