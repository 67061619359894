import React from 'react'
import './NewTeam.scss'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FormattedMessage } from 'react-intl'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { BsLink45Deg } from 'react-icons/bs'
import axios from 'axios'
import { useState } from 'react'
import TeamType from './TeamType/TeamType'
import { FiEdit3 } from 'react-icons/fi'
import { getToken } from '../../../../components/pages/Auth/Token'
import apiUrl from '../../../../api'
const NewTeam = (props) => {
    const { newTeam, setNewTeam } = props;
    const [name, setTeamName] = useState('');
    const [projecttype, setSelectedPro] = useState(null);
    const [openPro, setOpenPro] = useState(false);
    const [teamDescripiton, setTeamDescription] = useState('');
    const [nextMod, setNextMod] = useState(false);
    const [teamEmails, setTeamEmails] = useState('')
    const [isFormValid, setIsFormValid] = useState(false);

    const handleProSelect = (pro) => {
        setSelectedPro(pro);
        setOpenPro(false);
    };

    const handlePro = () => {
        setOpenPro(!openPro)
    }

    console.log(nextMod);

    const handleNextClick2 = (e) => {
        e.preventDefault();
        if (name === '') {
            alert('Please enter team name');
            setIsFormValid(false);
            return;
        }
        setNextMod(true);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = getToken();
        try {
            const response = await axios.post(apiUrl + 'user/team', {
                name,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const createdTeam = response.data;
            console.log(createdTeam);
            alert('Team successfully created')


        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div className='newteam-container' data-aos='zoom-in' data-aos-duration="400">
            <form >
                {nextMod ? (
                    <div>
                        <div className="npr-top flex">
                            <p><FormattedMessage id='ntm-add2' defaultMessage='Add People to *(new team name)*'></FormattedMessage></p>
                            <AiOutlineCloseCircle onClick={() => setNewTeam(false)} />
                        </div>

                        <p id='t222-tx'>Type emails of people you want to add to your team. You can also invite others as guests.</p>

                        <div className='tm-emails'>
                            <div className="tm-em-top flex-b">
                                <p>Email or Username</p>
                                <div id='skip'>Skip this step</div>
                            </div>

                            <div className="tm-em-area">
                                <FiEdit3 id='tm-ic' />
                                <textarea
                                    className='tm-ema'
                                    placeholder='Write emails here'
                                    value={teamEmails}
                                    onChange={(e) => setTeamEmails(e.target.value)}
                                ></textarea>
                            </div>
                        </div>

                        <div className='npr-bottom flex'>
                            <p > <BsLink45Deg id='linkk' /> Copy invite link</p>
                            <button onClick={() => setNextMod(false)} id='pr-cancel'>Cancel</button>
                            <button onClick={handleSubmit} type='submit' id='pr-nextt'>Create Team</button>
                        </div>
                    </div>

                ) : (
                    <>
                        <div className="npr-top flex">
                            <p><FormattedMessage id='ntm-add' defaultMessage='Add New Team'></FormattedMessage></p>
                            <AiOutlineCloseCircle onClick={() => setNewTeam(false)} />
                        </div>

                        <div className="npr-name">
                            <p><FormattedMessage id='project-name' defaultMessage='Team Name'></FormattedMessage></p>
                            <div className="npr-name-area flex">
                                <div className="nprname-icon"></div>
                                <input
                                    type="text"
                                    id='pr-name'
                                    name='pr-name'
                                    value={name}
                                    onChange={(e) => setTeamName(e.target.value)}
                                    placeholder='Enter team name'
                                />
                            </div>
                        </div>

                        <div className="dp-drops flex">
                            <div className="dp-teams dp-drp">
                                <span>Project Type</span>
                                <div className="dp-prj dp-inner flex-b" onClick={handlePro}>
                                    <div className="dpprj-icon"></div>
                                    <p>{projecttype || "Private Project"}</p>
                                    <MdKeyboardArrowDown />
                                    {openPro && <TeamType
                                        handleProSelect={handleProSelect}
                                        setOpenPro={setOpenPro}
                                    ></TeamType>}
                                </div>
                            </div>

                        </div>

                        <div className="tm-description">
                            <p><FormattedMessage id='pr-desc' defaultMessage='Description '></FormattedMessage><span>(optional)</span></p>
                            <textarea
                                id='description'
                                name='description'
                                value={teamDescripiton}
                                onChange={(e) => setTeamDescription(e.target.value)}
                                placeholder='Add team description'
                            />
                        </div>



                        <div className='npr-bottom flex'>
                            <p > <div className='magic'></div>Use existing Team as template</p>
                            <button onClick={() => setNewTeam(false)} id='pr-cancel'>Cancel</button>
                            <button onClick={handleNextClick2} type='submit' id='pr-next'>Next</button>
                        </div>
                    </>
                )}


            </form>
        </div>
    )
}

export default NewTeam