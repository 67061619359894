import React, { useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import './UserMenu.scss'
import { MdOutlineMail } from 'react-icons/md'
import { userData } from './data'
import { Link } from 'react-router-dom'
import { RiArrowUpSFill } from 'react-icons/ri'
import Status from './Status/Status'

const useClickOutside = (handler) => {
    let domNode = useRef();

    useEffect(() => {
        let maybeHandler = (event) => {
            if (!domNode.current.contains(event.target)) {
                handler();
            }
        };
        document.addEventListener("mousedown", maybeHandler);
        return () => {
            document.removeEventListener("mousedown", maybeHandler);
        };
    });
    return domNode;
};

const handleSignOut = () => {
    localStorage.removeItem('token');
    window.location.href = '/Login';
    // alert('Logged Out!');
};


const UserMenu = (props) => {
    const { user } = props;
    const [openStatus, setStatus] = useState(false)
    const domNode = useClickOutside(() => {
        props.setTrigger(false);
    });
    const handleStatus = (e) => {
        e.stopPropagation();
        setStatus(!openStatus)
    }

    return (
        <div ref={domNode} className='user-menu' data-aos='fade-up' data-aos-duration='300'>
            <div className="inner-user">
                <RiArrowUpSFill className='arr-up' />
                <div className="user-info flex">
                    <div className="user-img"></div>
                    <div className="user-content flex-c">
                        <div className="user-name flex">
                            <p>{user.name}</p>
                            <button className='smilee flex' onClick={(e) => handleStatus(e)}>
                                <div className="smile"></div>
                                {openStatus && (
                                    <Status triger={openStatus} setTriger={setStatus}></Status>
                                )}
                            </button>
                            <div className="free-plan flex">
                                <div className="fp-icon"></div>
                                <p><FormattedMessage id='Free-Plan' defaultMessage='Free Plan'></FormattedMessage></p>
                            </div>
                        </div>
                        <div className="user-email flex">
                            <MdOutlineMail />
                            <p>{user.email}</p>
                        </div>
                        <div className="user-stat flex">
                            <div className="act-btn"></div>
                            <p><FormattedMessage id='available' defaultMessage='Available for all'></FormattedMessage></p>
                        </div>
                    </div>
                </div>

                <div className="user-options flex-c">
                    {
                        userData.map((u) => {
                            return (
                                <Link to={u.to} key={u.id} className='uinfo-item flex'>
                                    <div className="ui-icon" style={{ content: `url(${u.icon})` }}></div>
                                    <p>{u.text}</p>
                                </Link>
                            )
                        })
                    }
                </div>
                <div onClick={handleSignOut} className="ui-auth flex">
                    <div className="auth-icon"></div>
                    <p ><FormattedMessage id='sign-out' defaultMessage='Sign Out'></FormattedMessage></p>
                </div>
            </div>
        </div>
    )
}

export default UserMenu