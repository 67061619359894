import React from 'react'
import './BoxComments.scss'
import { Link } from 'react-router-dom';

const BoxComments = (props) => {
    const {comm} = props;


  return (
    <div className='tn-box-items'>
        {comm?.map((item) => {
            const itemName = item.screen_info?.name;
            const truncatedName = itemName.length > 15 ? itemName.slice(0, 15) + '...' : itemName;

            const currentDate = new Date();
            const createdAtDate = new Date(item.created_at);

            if (currentDate.getTime() - createdAtDate.getTime() <= 60480) { // 7 days in milliseconds
                const formattedDate = "now";
                console.log(formattedDate);
            } else {
                const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
                const formattedDate = createdAtDate.toLocaleDateString('en-US', options);
                const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };
                const formattedTime = createdAtDate.toLocaleTimeString('en-US', optionsTime);
                console.log(formattedDate + ' ' + formattedTime);
            }
            return(
                <div key={item.id} className='tn-top-item'>
                    <div className="tntop-user" style={{background: `url(${item.user_info?.avatar_url})`}}>

                    </div>
                    <div className="tntop-content">
                        <p><h4>{item.user_info.name} {item.user_info.lastname}</h4> comented on <Link to={`/Dashboard/Screens/${item.project_info?.id}/${item.project_info?.project_title}/${item.screen_info?.id}/${item.screen_info?.name}`}>{truncatedName}</Link></p>
                        <span id='tn-cr'>  {currentDate.getTime() - createdAtDate.getTime() <= 60480 ? (
                                        "now"
                                    ) : (
                                        <>
                                            {createdAtDate.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })}{' '}
                                            {createdAtDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}
                                        </>
                                    )}</span>
                        <div className="com-place">
                            <p>{item.comment}</p>
                        </div>

                        <div className="comm-look" style={{content:`url(${item.screen_info.screen_url_thumb})`}}>

                        </div>
                    </div>
                </div>
            )
        })}
    </div>
  )
}

export default BoxComments