import React, { useState } from 'react';
import './NextNew.scss';
import { AiOutlinePlusCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { FormattedMessage } from 'react-intl';
import { npr2Data } from '../../../../pages/Overview/data';
import { RiArrowDownSFill } from 'react-icons/ri';
import { MdKeyboardArrowDown } from 'react-icons/md'
import Drop1 from './Drop1/Drop1';
import Drop2 from './Drop2/Drop2';


const NextNew = (props) => {
  const { handleClick2, setShowFirstForm } = props;
  const [selectedDevice, setSelectedDevice] = useState(0);
  const [drop, setDrop] = useState(false);
  const [drop2, setDrop2] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState(null)
  const [selectedAndro, setSelectedAndro] = useState(null)


  const handlePhoneSelect = (phone) => {
    setSelectedPhone(phone);
    setDrop(false);
  };

  const handleAndroSelect = (andro) => {
    setSelectedAndro(andro);
    setDrop2(false);
  };

  const handleDrop = (index) => {
    setDrop(prevDrop => index === 1 ? !prevDrop : prevDrop);
    setDrop2(prevDrop2 => index === 3 ? !prevDrop2 : prevDrop2);
  };

  const handleDevice = (index) => {
    setSelectedDevice(index);
  };

  const handleItemClick = (index) => {
    return () => handleDevice(index);
  };

  const handleClick = (e) => {
    e.preventDefault()
    setShowFirstForm(true)
  }


  return (
    <div className='npr2' data-aos='zoom-in' data-aos-duration='400'>
      <div className='npr-top flex-b'>
        <p>
          <FormattedMessage id='npr-add' defaultMessage='Add New Project' />
        </p>
        <AiOutlineCloseCircle onClick={handleClick2} />
      </div>
      <div className='npr2-content'>
        <p id='npr2-tx'>Prototype</p>
        <div className='npr2-items flex'>
          {npr2Data.map((n, index) => {
            return (
              <div
                key={n.id}
                className='npr2-item flex-c'
                onClick={handleItemClick(index)}
                style={{
                  border: `1px solid ${index === selectedDevice ? '#4280EB' : '#e0e0e0'
                    }`,

                }}
              >
                <RiArrowDownSFill
                  className='npr-arr'
                  style={{
                    bottom: `${index === selectedDevice ? "-18px" : '0'}`
                  }}
                />
                <div className='npr icon'>{n.icon}</div>
                <div className='npr2-title'>
                  <p className='flex' onClick={() => handleDrop(index)}>
                    {index === 1 ? (selectedPhone || n.title) : (index === 3 ? (selectedAndro || n.title) : n.title)}
                    <MdKeyboardArrowDown />
                  </p>
                  {drop && index === 1 && <Drop1
                    handlePhoneSelect={handlePhoneSelect}
                    setDrop={setDrop}

                  />}
                  {drop2 && index === 3 && <Drop2
                    handleAndroSelect={handleAndroSelect}
                    setDrop2={setDrop2}
                  />}

                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className='npr-bottom flex'>
        <button id='pr-cancel' onClick={handleClick}>Cancel</button>
        <button type='submit' id='pr-next'>Create Project</button>
      </div>
    </div>
  );
};

export default NextNew;
