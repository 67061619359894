import axios from 'axios';


export function getToken() {
    return localStorage.getItem('token');
}

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

