import { lazy, Suspense, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Loading from './components/shared/Loading/Loading';
import ScrollToTop from './ScrollToTop';
import './App.scss';
import Aos from 'aos';
import "aos/dist/aos.css";
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import Overview from './dashboard/pages/Overview/Overview'
import { getToken } from './components/pages/Auth/Token';
import ScreenPrev from './dashboard/pages/ScreenPreview/ScreenPrev';
import ProjectPrev from './dashboard/pages/ProjectPreview/ProjectPrev'
import ProjectPreview from './dashboard/pages/ProjectPreview/ProjectPreview';

const Home = lazy(() => import('./pages/Home/Home'));
const Landing1 = lazy(() => import('./pages/Landing1/Landing1'));
const Landing2 = lazy(() => import('./pages/Landing2/Landing2'));
const Landing3 = lazy(() => import('./pages/Landing3/Landing3'));
const Landing4 = lazy(() => import('./pages/Landing4/Landing4'));
const Login = lazy(() => import('./pages/Login/Login'));
const Signup = lazy(() => import('./pages/Signup/Signup'));
const OvPage = lazy(() => import('./dashboard/pages/Overview/OvPage/OvPage'))
const Projects = lazy(() => import('./dashboard/pages/AllProjects/Projects/Projects'))
const StarredProjects = lazy(() => import('./dashboard/pages/StarredProjects/Projects/Projects'))
const Screens = lazy(() => import('./dashboard/pages/Screens/Screens'))
const ScreenView = lazy(() => import('./dashboard/pages/Screens/ScreenView/ScreenView'))
const Comments = lazy(() => import("./dashboard/pages/Comments/Comments"))
const Shares = lazy(() => import('./dashboard/pages/Shares/Shares'))
const Search = lazy(() => import('./dashboard/pages/AllProjects/Search/Search'))
const ScComments = lazy(() => import('./dashboard/pages/Screens/Comments/ScComments'))
const People = lazy(() => import('./dashboard/pages/Screens/People/People'))
const Activity = lazy(() => import('./dashboard/pages/Screens/Activity/ScActivity'))
const Shared = lazy(() => import('./dashboard/pages/Screens/Shared/Shared'))
const AccountSettings = lazy(() => import("./dashboard/pages/AccSettings/AccSettings"))
const RemoteUploads = lazy(() => import("./dashboard/pages/RemoteUploads/RemoteUploads"))
const LastUploads = lazy(() => import("./dashboard/pages/LastUploads/LastUploads"))
const FTPUploads = lazy(() => import("./dashboard/pages/FTPUploads/FTPUploads"))
const Teams = lazy(() => import("./dashboard/pages/Teams/Teams"))
const Team = lazy(() => import("./dashboard/pages/Team/Team"))
const Settings = lazy(() => import('./dashboard/pages/Screens/Settings/Settings'))
const ScreenPreview = lazy(() => import('./dashboard/pages/ScreenPreview/ScreenPreview'))
const ProjectPreview2 = lazy(() => import('./dashboard/pages/Screens/PrjPreview/PrjPreview'))

const ScreenPreview2 = lazy(() => import('./dashboard/pages/Screens/ScreenView/ScreenPrev/ScreenPreview'))
const Upgrade  = lazy(() => import('./dashboard/pages/Upgrade/UpgradeMain'))
function App() {

  const language = useSelector((state) => state.language.language);

  useEffect(() => {
    Aos.init({
      duration: 1250,
      once: true
    })
  }, [])


  function isAuthenticated() {
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expirationDate');

    if (token && expirationDate) {
      const currentTime = new Date().getTime();
      const expirationTime = new Date(expirationDate).getTime();

      return currentTime < expirationTime;
    }

    return true;
  }


  return (
    <Router>



      <Suspense fallback={<Loading />}>
        <IntlProvider>
          <ScrollToTop />

          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/Landing1" element={<Landing1 />} />
            <Route path="/Landing2" element={<Landing2 />} />
            <Route path="/Landing3" element={<Landing3 />} />
            <Route path="/Landing4" element={<Landing4 />} />
            <Route path="/Login" element={<Login />} />
            {/* <Route path="/Signup" element={<Signup />} /> */}
            {isAuthenticated() ? (
              <>
                <Route exact path="/Dashboard/Overview" element={<Overview />} />
                <Route exact path="/Dashboard/Projects" element={<Projects />} />
                <Route exact path="/Dashboard/StarredProjects" element={<StarredProjects />} />

                <Route exact path="/Dashboard/Search/:title" element={<Search />} />
                <Route path="/Dashboard/Screens/:projectId/:projectTitle" element={<Screens />} />
                <Route path='/Dashboard/Screens/:projectId/:projectTitle/:screenTitle/:screenId' element={<ScreenView />} />
                <Route path='/Dashboard/Comments' element={<Comments />} />
                <Route path='/Dashboard/Shares' element={<Shares />} />
                <Route path='/Dashboard/Screens/:projectId/:projectTitle/Comments' element={<ScComments />} />
                <Route path='/Dashboard/Screens/:projectId/:projectTitle/Roles_Permission' element={<People />} />
                <Route path='/Dashboard/Screens/:projectId/:projectTitle/Activity' element={<Activity />} />
                <Route path='/Dashboard/Screens/:projectId/:projectTitle/Shared' element={<Shared />} />
                <Route path='/Dashboard/Screens/:projectId/:projectTitle/Settings' element={<Settings />} />
                <Route path='/Dashboard/Team/:teamId/:teamTitle' element={<Team />} />

                <Route path='/Dashboard/:projectId/:projectTitle/ScreenPreview/:screenId/:screenTitle' element={<ScreenPreview2 />} />
                <Route path='/Dashboard/:projectId/:projectTitle/ProjectPreview' element={<ProjectPreview2 />} />

                <Route path='/Dashboard/AccountSettings' element={<AccountSettings />} />
                <Route path='/Dashboard/RemoteUploads' element={<RemoteUploads />} />
                <Route path='/Dashboard/LastUploads' element={<LastUploads />} />
                <Route path='/Dashboard/FTPUploads' element={<FTPUploads />} />
                <Route path='/Dashboard/Teams' element={<Teams />} />
                <Route path='/Dashboard/Upgrade' element={<Upgrade />} />


              </>
            ) : (
              <Route exact path="/" component={<Login />} />
            )}
            <Route path='/sharelink/:link' element={<ScreenPreview />} />
            <Route path='/shareProject/:link' element={<ProjectPreview />} />

            <Route path='/sharelink/:link/:screenName/:screenId' element={<ScreenPrev />} />
            <Route path='/shareProject/:link/:projectName/:projectId' element={<ProjectPrev />} />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>

        </IntlProvider>
      </Suspense>

    </Router>
  );
}

export default App;
