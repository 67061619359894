import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import './Guides.scss'
import { HiArrowRight } from 'react-icons/hi'
import { guidesData } from './data'

const Guides = () => {
    return (
        <div className='guides-container'>
            <div className="guides-top flex-b">
                <p><FormattedMessage id='gd-title' defaultMessage='Guides'></FormattedMessage></p>
                <Link to='/' className='flex'><FormattedMessage id='gd-link' defaultMessage='Go to Knowledge Center'></FormattedMessage><HiArrowRight /></Link>
            </div>

            <div className="gd-items flex-b">
                {guidesData.map((g) => {
                    return (
                        <Link to={g.to} key={g.id} className='gd-item flex'>
                            <div className="gd-inner"></div>
                            <div className="gd-iconn">
                                <div className="gd-icon" style={{ content: `url(${g.icon})` }}></div>
                            </div>
                            <div className="gd-in-con flex">
                                <div className="gd-content">
                                    <span>{g.title}</span>
                                    <p>{g.text}</p>
                                </div>

                                <HiArrowRight />
                            </div>


                        </Link>
                    )
                })}
            </div>
        </div>
    )
}

export default Guides