import React,{useState} from 'react'
import './PersonalDrop.scss'

const PersonalDrop = ({ handlePersSelect, setOpenPers }) => {

    const handleItemClick = (pers) => {
        handlePersSelect(pers);
        setOpenPers(false);
      };

  return (
    <div className='pd-box'>
        <div className="pd-items">
            <p onClick={() => handleItemClick('Perfect Team')}>Perfect Team</p>
            <p onClick={() => handleItemClick('We Design')}>We Design</p>
            <p onClick={() => handleItemClick('Personal')}>Personal</p>

        </div>
    </div>
  )
}

export default PersonalDrop