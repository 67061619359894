import React, { useEffect, useState, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { BiSearch, BiFilterAlt } from 'react-icons/bi'
import { MdOutlineSort, MdKeyboardArrowDown, MdOutlineMoreHoriz } from 'react-icons/md'
import { RiArrowUpSFill } from 'react-icons/ri'
import './Ongoing.scss'
const Ongoing = ({ projects }) => {


    const ongoing = projects?.filter((p) => p.finished === "false");
    return (
        <div className='ongoing-component'>
            <div className="ong-top flex-b">
                <div className="ongtop-left flex">
                    <p><FormattedMessage id='ong-t' defaultMessage='On-going Projects'></FormattedMessage></p>
                    <div className="ong-nr flex">
                        {projects?.length}
                    </div>
                    <Link to='/'><FormattedMessage id='ong-link' defaultMessage='View On Going Projects'></FormattedMessage></Link>
                </div>

                <div className="ongtop-right flex">
                    <BiSearch />
                    <BiFilterAlt />
                    <div className="ov-filter flex-b">
                        <MdOutlineSort />
                        <p>Sort by</p>
                        <MdKeyboardArrowDown />
                    </div>
                </div>

            </div>

            <div className="ongoing-titles flex-b">
                <div className="ong-name flex">
                    <p>Name</p>
                    <div className="ong-arrow"></div>
                </div>
                <div className="ong-people flex">
                    <p>People</p>

                </div>
                <div className="ong-screens flex">
                    <p>Screens</p>
                </div>
                <div className="ong-sharelink flex">
                    <p>Share Link</p>
                </div>
                <div className="ong-blank flex"></div>
            </div>

            <div className="ongoing-items">
                {ongoing?.map((o) => {
                    return (
                        <div key={o.id} className='ongoing-item flex-b'>
                            <div className="ong-namee flex">
                                <div className="ong-icon" style={{ content: `url(${o.icon})` }}></div>
                                <div className="ong-info">
                                    <p>{o.project_title}</p>

                                    <span className='flex'>{o.members.map((e) => e.name)}
                                        <div className="verify"></div>
                                        <div className="ong-hover">
                                            <div className="m2-inhv">
                                                <RiArrowUpSFill />
                                                <p>Project Owner</p>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div className="ong-pp flex">
                                <div className="ong-profile1" ></div>
                                <div className="ong-profile2" ></div>
                                <div className="ong-profile3" ></div>
                            </div>
                            <div className="ong-screenss flex">
                                <div className="ong-sc-icon"></div>
                                <span>{(o.screen_count)}</span>
                            </div>
                            <div className="ong-sharee flex">
                                <div className="ong-sh-icon"></div>
                            </div>
                            <div className="ong-blankk flex">
                                <MdOutlineMoreHoriz />
                            </div>
                        </div>
                    )
                })}
            </div>




        </div>
    )
}

export default Ongoing