import React, { useState, useEffect } from "react";
import { BsSun, BsMoon } from "react-icons/bs";
import './useThemeSwitcher.scss'
import { FormattedMessage } from "react-intl";

function ThemeSwitcher(props) {

    const [mode, setMode] = useState(() => localStorage.getItem("mode"));

    useEffect(() => {
        window.addEventListener("storage", setPreferedTheme);
        return () => {
            window.removeEventListener("storage", setPreferedTheme);
        };
    }, []);

    const setPreferedTheme = () => {
        const _mode = localStorage.getItem("mode");
        if (_mode) {
            setMode(_mode);
        } else {
            setMode("light");
        }
    };

    useEffect(() => {
        if (mode === "dark") {
            document.body.classList.add("dark-mode");
            localStorage.setItem("mode", "dark");
        } else {
            document.body.classList.remove("dark-mode");
            localStorage.setItem("mode", "light");
        }
    }, [mode]);

    return (
        <div className="changer">
            <div className="ch-light">
                Light
                <BsSun />
            </div>
            <a
                className="mode-switcher-container"
            >
                {mode === "dark" ?
                    <div className='mode-switch' onClick={() =>
                        setMode(mode => (mode === "dark" ? "light" : "dark"))
                    }>
                        <div className="iconn"></div>
                        <div className="iconn active"></div>

                    </div>
                    :
                    <div className='mode-switch' onClick={() =>
                        setMode(mode => (mode === "dark" ? "light" : "dark"))
                    }>
                        <div className="iconn active"></div>
                        <div className="iconn"></div>
                    </div>
                }


                <div className={mode === "dark" ? 'nav-mode-switch nav-mode-switch-active' : 'nav-mode-switch'}
                    onClick={() => setMode(mode => (mode === "dark" ? "light" : "dark"))}
                >
                    <div className='icon'></div>
                </div>

            </a>
            <div className="ch-dark">
                <BsMoon />
                Dark
            </div>
        </div>
    );
}

export default ThemeSwitcher