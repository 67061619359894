import React, { useEffect, useState } from 'react'
import './Menu2.scss'
import { BiSearch } from 'react-icons/bi'
import { BsPlusCircleFill } from 'react-icons/bs'
import { FormattedMessage } from 'react-intl'
import { MdKeyboardArrowUp } from 'react-icons/md'
import { FiMoreHorizontal } from 'react-icons/fi'
import { RiArrowUpSFill } from 'react-icons/ri'
import { menuData } from './data'
import { Link } from 'react-router-dom'
import TeamDrop from './TeamDrop/TeamDrop'
import NewTeam from './NewTeam/NewTeam'
import axios from 'axios'
import apiUrl from '../../../api'
import { getToken } from '../../../components/pages/Auth/Token'
import icon1 from '../../../assets/Dashboard/Overview/Sidebar/t1.png'
import { HiUsers } from 'react-icons/hi'

const Menu2 = (props) => {
    const { isOpen, newTeam, setNewTeam } = props;
    const [openMenu, setOpenMenu] = useState(true);
    const [openTeam, setOpenTeam] = useState(false);
    const [teams, setTeams] = useState([]);
    const handleMenu = () => {
        setOpenMenu(!openMenu);
    }
    const handleTeam = (e) => {
        e.preventDefault()
        setOpenTeam(!openTeam)
    }

    useEffect(() => {
        const getTeams = async () => {
            try {
                const response = await axios.get(apiUrl + 'user/teams', {
                    headers: {
                        Authorization: `Bearer ${getToken()}`
                    }
                });
                setTeams(response.data?.teams);
            } catch (error) {
                console.log(error);
            }
        }

        getTeams();
    }, []);

    return (
        <div className='menu2-container' onClick={(e) => e.stopPropagation()}
            style={{ borderTop: isOpen?.isOpenSideMenu ? "1px solid #e2e2e2" : '' }}
        >


            <div className="m2-top flex-b">
                <div className="m2-teams flex">
                    <div className="tms-icon"></div>
                    {isOpen?.isOpenSideMenu && <Link style={{color: `#000000`}} to='/Dashboard/Teams'><FormattedMessage id='teams' defaultMessage='Teams'></FormattedMessage></Link>}
                </div>
                {isOpen?.isOpenSideMenu && <div className="tms-tools flex">
                    <button><BiSearch /></button>
                    <button onClick={() => setNewTeam(true)}><BsPlusCircleFill className='plus' /></button>
                    <button onClick={handleMenu}><MdKeyboardArrowUp className={openMenu ? "arr-m2" : "arr-rot"} /></button>
                </div>}
            </div>
            {openMenu &&
                <>
                    {teams?.map((m) => {
                        return (
                            <div className='m2-item flex-b'>
                                <div className="m2-title flex">
                                    <div className="m2-iconn flex">
                                        <div className="m2-icon" style={{ content: `url(${icon1})` }}></div>
                                    </div>
                                    {isOpen?.isOpenSideMenu && <p>{m?.name}</p>}
                                </div>
                                {isOpen?.isOpenSideMenu &&
                                    <div className="m2-tools flex">
                                        <div className="m2-pm flex">
                                            <div className="m2-hover">
                                                <div className="m2-inhv">
                                                    <RiArrowUpSFill />
                                                    <p>Members</p>
                                                </div>
                                            </div>
                                            <div className="m2pm-icon" style={{ content: `url(${<HiUsers />})` }}><HiUsers /></div>
                                            {/* <span>{m.nr}</span> */}
                                        </div>

                                        <button className='dots-menu' >
                                            <FiMoreHorizontal />
                                        </button>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </>
            }

        </div>
    )
}

export default Menu2