import icon1 from '../../../../../assets/Dashboard/Overview/Nav/New/pr1.png'
import icon2 from '../../../../../assets/Dashboard/Overview/Nav/New/pr2.png'
import icon3 from '../../../../../assets/Dashboard/Overview/Nav/New/pr3.png'
import icon4 from '../../../../../assets/Dashboard/Overview/Nav/New/pr4.png'
import icon5 from '../../../../../assets/Dashboard/Overview/Nav/New/pr5.png'
import icon6 from '../../../../../assets/Dashboard/Overview/Nav/New/pr6.png'
import icon7 from '../../../../../assets/Dashboard/Overview/Nav/New/pr7.png'
import icon8 from '../../../../../assets/Dashboard/Overview/Nav/New/pr8.png'
import icon9 from '../../../../../assets/Dashboard/Overview/Nav/New/pr9.png'



export const colors = [
    {
        id: 1,
        color: '#302A5D',
    },
    {
        id: 2,
        color: '#AEA5F8',
    },
    {
        id: 3,
        color: '#FFB341',
    },
    {
        id: 4,
        color: '#6DBEB3',
    },
    {
        id: 5,
        color: '#F15252',
    },
    {
        id: 6,
        color: '#0084FF',
    },
    {
        id: 7,
        color: '#90A2B4',
    },
    {
        id: 8,
        color: '#62C88E',
    },
    {
        id: 9,
        color: '#5F41FF',
    },
]


export const icons = [
    {
        id: 1,
        icon: icon1,
    },
    {
        id: 2,
        icon: icon2,
    },
    {
        id: 3,
        icon: icon3,
    },
    {
        id: 4,
        icon: icon4,
    },
    {
        id: 5,
        icon: icon5,
    },
    {
        id: 6,
        icon: icon6,
    },
    {
        id: 7,
        icon: icon7,
    },
    {
        id: 8,
        icon: icon8,
    },
    {
        id: 9,
        icon: icon9,
    },
]