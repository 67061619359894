import icon1 from '../../../assets/Dashboard/Overview/Ovpage/Guides/ico1.png'
import icon2 from '../../../assets/Dashboard/Overview/Ovpage/Guides/ico2.png'
import icon3 from '../../../assets/Dashboard/Overview/Ovpage/Guides/ico3.png'
import icon4 from '../../../assets/Dashboard/Overview/Ovpage/Guides/ico4.png'
import icon5 from '../../../assets/Dashboard/Overview/Ovpage/Guides/ico5.png'

import { ReactComponent as Npr1 } from '../../../assets/Dashboard/Overview/Nav/New/npr1.svg'
import { ReactComponent as Npr2 } from '../../../assets/Dashboard/Overview/Nav/New/npr2.svg'
import { ReactComponent as Npr3 } from '../../../assets/Dashboard/Overview/Nav/New/npr3.svg'
import { ReactComponent as Npr4 } from '../../../assets/Dashboard/Overview/Nav/New/npr4.svg'
import { ReactComponent as Npr5 } from '../../../assets/Dashboard/Overview/Nav/New/npr5.svg'
import { ReactComponent as Npr6 } from '../../../assets/Dashboard/Overview/Nav/New/npr6.svg'
import { ReactComponent as Npr7 } from '../../../assets/Dashboard/Overview/Nav/New/npr7.svg'
import { ReactComponent as Npr8 } from '../../../assets/Dashboard/Overview/Nav/New/npr8.svg'


export const sideData = [
    {
        id:1,
        icon:icon1,
        title:'Unlimited people in a team ',
    },
    {
        id:2,
        icon:icon2,
        title:'Password protection ',
    },
    {
        id:3,
        icon:icon3,
        title:'Private comments',
    },
    {
        id:4,
        icon:icon4,
        title:'Advanced usability test report',
    },
    {
        id:5,
        icon:icon5,
        title:'AI automations',
    },
]


export const npr2Data = [
    {
        id:1,
        icon: <Npr1/>,
        title:'Web'
    },
    {
        id:2,
        icon: <Npr2/>,
        title:'iPhone'
    },
    {
        id:3,
        icon: <Npr3/>,
        title:'iPad'
    },
    {
        id:4,
        icon: <Npr4/>,
        title:'Android'
    },
    {
        id:5,
        icon: <Npr5/>,
        title:'Tablet (iPad)'
    },
    {
        id:6,
        icon: <Npr6/>,
        title:'Apple Watch'
    },
    {
        id:7,
        icon: <Npr7/>,
        title:'Android Wear'
    },
    {
        id:8,
        icon: <Npr8/>,
        title:'Custom'
    },
]