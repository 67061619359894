import React, { useEffect, useState } from 'react'
import './Teams.scss'
import { FormattedMessage } from 'react-intl'
import axios from 'axios'
import apiUrl from '../../../../../api'
import { getToken } from '../../../../../components/pages/Auth/Token'
import { Link } from 'react-router-dom'
import { BiSearch, BiFilterAlt } from 'react-icons/bi'
import { MdOutlineSort, MdKeyboardArrowDown, MdOutlineMoreHoriz, MdContentCopy } from 'react-icons/md'
import icon1 from '../../../../../assets/Dashboard/Overview/Ovpage/Teams/icon4.png'

const Teams = () => {
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        const getTeams = async () => {
            try {
                const response = await axios.get(apiUrl + 'user/teams', {
                    headers: {
                        Authorization: `Bearer ${getToken()}`
                    }
                });
                setTeams(response.data?.teams);
            } catch (error) {
                console.log(error);
            }
        }

        getTeams();
    }, []);

    console.log("ekipet", teams);


    return (
        <div className='teams-container'>
            <div className="ong-top flex-b">
                <div className="ongtop-left flex">
                    <p><FormattedMessage id='tms-t' defaultMessage='Teams'></FormattedMessage></p>
                    <div className="ong-nr flex">
                        {teams?.length}
                    </div>
                    <Link to='/'><FormattedMessage id='tms-link' defaultMessage='View All Teams'></FormattedMessage></Link>
                </div>

                <div className="ongtop-right flex">
                    <BiSearch />
                    <BiFilterAlt />
                    <div className="ov-filter flex-b">
                        <MdOutlineSort />
                        <p>Sort by</p>
                        <MdKeyboardArrowDown />
                    </div>
                </div>

            </div>

            <div className="ongoing-titles flex-b">
                <div className="ong-name flex">
                    <p>Name</p>
                    <div className="ong-arrow"></div>
                </div>
                <div className="ong-people flex">
                    <p>People</p>
                </div>
                <div className="ong-screens flex">
                    <p>Plan</p>
                </div>
                <div className="ong-blank flex"></div>
            </div>

            <div className="ongoing-items">
                {teams?.map((o) => {
                    return (
                        <div key={o.id} className='ongoing-item flex-b'>
                            <div className="ong-namee flex">
                                <div className="ong-icon" style={{ content: `url(${icon1})` }}></div>
                                <div className="ong-info">
                                    <p>{o.name}</p>

                                </div>
                            </div>
                            <div className="comp-marked flex">
                                <div className="user-line"></div>
                                <p>1</p>
                            </div>
                            <div className="ong-pp flex">
                                <p>Free</p>
                            </div>

                            <div className="ong-blankk flex">
                                <MdOutlineMoreHoriz />
                            </div>
                        </div>
                    )
                })}
            </div>




        </div>
    )
}

export default Teams