import React, { useEffect, useState, useCallback } from 'react'
import './Comments.scss'
import { RiUser3Line } from 'react-icons/ri'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { BiFilterAlt } from 'react-icons/bi'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { MdOutlineSort, MdKeyboardArrowDown, MdNotifications } from 'react-icons/md'
import { getToken } from '../../../../../components/pages/Auth/Token'
import apiUrl from '../../../../../api'
import axios from 'axios'

const Comments = () => {


  const [commentss, setComments] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          apiUrl + `user/project-comments`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        const newProjects = response.data;
        localStorage.setItem("comments", JSON.stringify(newProjects));
        setComments(newProjects.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);




  // const items = Array.isArray(commentss) ? commentss.flatMap((c) => c?.comments) : [];
  // const coms = items?.flatMap((it) => it);
  console.log(commentss);

  return (
    <div className='ov-comments-container'>
      <div className="ov-com-top flex-b">
        <div className="ongtop-left flex">
          <p><FormattedMessage id='ong-t' defaultMessage='Comments'></FormattedMessage></p>
          <Link to='/Dashboard/Comments'><FormattedMessage id='view-all' defaultMessage='View All'></FormattedMessage></Link>
        </div>

        <div className="ongtop-right flex">
          <BiFilterAlt />
          <div className="ov-from flex-b">
            <RiUser3Line />
            <span>From:</span>
            <p>Anyone</p>
            <MdKeyboardArrowDown />
          </div>
          <div className="ov-filter flex-b">
            <MdOutlineSort />
            <p>Sort by</p>
            <MdKeyboardArrowDown />
          </div>
        </div>

      </div>
      {commentss?.slice(0, 10).map((c) => {
        const createdAt = new Date(c?.created_at);
        const formattedDate = createdAt.toLocaleString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        });
        return (
          <>


            <div className="com-comments">
              <div className="user2 flex">
                <div className="c-ico comm2-icon"></div>
                <div className="com-content">
                  <div className="com-title flex">
                    <span>{`${c.user_info.name} ${c.user_info.lastname}`}</span>
                    <p>marked a comment as completed on</p>
                    <Link to={`/Dashboard/Screens/${c.project_info.id}/${c.project_info.project_title}`}>{c.project_info.project_title}</Link> in
                    <Link to={`/Dashboard/Screens/${c.project_info.id}/${c.project_info.project_title}/${c.screen_info.name}/${c.screen_info.id}`}>{c.screen_info.name}</Link>
                  </div>
                  <div className="com-date">
                    <p>{formattedDate}</p>
                  </div>
                  <div className="com-look">
                    <div className="comm-inner">
                      <div className="comin-top">
                        <div className="comintop-content cmt2 flex">
                          <div className="comm-icon2"></div>
                          <p>{c?.comment}</p>
                          <div className="check"><BsFillCheckCircleFill /></div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      })}





    </div>
  )
}

export default Comments