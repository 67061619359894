import React, { useState, useEffect } from 'react';
import './ProjectPrev.scss';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineAttachFile } from 'react-icons/md';
import { FiMoreHorizontal } from 'react-icons/fi';
import { BiCommentDetail } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import apiUrl from '../../../api';
import { getToken } from '../../../components/pages/Auth/Token';
import { BsEmojiSmile } from "react-icons/bs";
import { FiUnlock } from "react-icons/fi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { GrClose } from "react-icons/gr";
import { RiArrowUpSFill, RiDeleteBinLine } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
const ProjectPrev = () => {

  const { link } = useParams();
  const [project, setProject] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();
  const [commentBoxes, setCommentBoxes] = useState([]);
  const [commentPosition, setCommentPosition] = useState({ x: 0, y: 0 });
  const [mode, setMode] = useState(0);
  const [commenttype_id, setCommenttype_id] = useState(1);
  const [commentValue, setCommentValue] = useState("");
  // const [commSection, setCommSection] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lastUpdatedBox, setLastUpdatedBox] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [draggedPosition, setDraggedPosition] = useState({ x: 0, y: 0 });
  const [currentComment, setCurrentComment] = useState("");
  const [editedComment, setEditedComment] = useState("");
  const [editingComments, setEditingComments] = useState({});
  const [currentScreen, setCurrentScreen] = useState(null); // Initialize with null or a default value
  const [user, setUser] = useState(null);

  const axiosInstance = axios.create();

  console.log("projekti", project);

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );



  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get(apiUrl + 'user/me');
        const userData = response.data;
        setUser(userData);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // handle unauthorized error
        } else {
          console.log(error);
        }
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl + `share/project/${link}`);
        setProject(response.data);
      } catch (error) {
        console.log("error fetching data", error)
      }
    }
    fetchData();
  }, [link])

  const fetchComments = async () => {
    const token = getToken();

    try {
      const response = await axios.get(
        apiUrl + `user/project/${project?.id}/getcomments/${currentScreen?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );


      if (response.data && Array.isArray(response.data.comments)) {
        const commentGroups = response.data.comments.reduce(
          (groups, commentData) => {
            const key = `${commentData.position_x},${commentData.position_y}`;
            if (!groups[key]) {
              groups[key] = [];
            }
            groups[key].push(commentData);
            return groups;
          },
          {}
        );

        const newCommentBoxes = Object.keys(commentGroups).map((key, index) => {
          const [x, y] = key.split(",").map(Number);
          const comments = commentGroups[key];

          return {
            position: { x, y },
            comments,
            showCommentSection: index === lastUpdatedBox,
          };
        });

        setCommentBoxes(newCommentBoxes || []);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleEditComment = (commentId) => {
    setEditingComments((prevEditingComments) => ({
      ...prevEditingComments,
      [commentId]: true,
    }));
  };

  const handleMode = (index) => {
    setMode(index);
  };


  const handleSaveComment = async (commentId, editedComment) => {
    if (editingComments[commentId]) {
      const token = getToken();
      const payload = {
        comment: editedComment,
      };

      try {
        const response = await axios.post(
          apiUrl + `user/project/${project?.id}/updatecomment/${commentId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const updatedCommentBoxes = commentBoxes.map((comment) => {
          const updatedComments = comment.comments.map((c) => {
            if (c.comment === editedComment) {
              return { ...c, comment: editedComment };
            }
            return c;
          });

          return { ...comment, comments: updatedComments };
        });

        setCommentBoxes(updatedCommentBoxes);
      } catch (error) {
        console.log(error);
      }
    }
    setEditingComments(false);
    fetchComments();
  };

  const handleDragStart = (event, index) => {
    event.target.style.cursor = "move";
    event.dataTransfer.setData("text/plain", index);
    const x = event.nativeEvent.offsetX;
    const y = event.nativeEvent.offsetY;
    setDraggedPosition({ x, y });
  };


  const handleDragOver = (event) => {
    event.preventDefault();
    const image = event.target;
    const x = event.nativeEvent.offsetX;
    const y = event.nativeEvent.offsetY;
    setDraggedPosition({ x, y });
  };



  const handleDrop = (event) => {
    event.preventDefault();

    const index = event.dataTransfer.getData("text/plain");
    const x = draggedPosition.x;
    const y = draggedPosition.y;
    const updatedCommentBoxes = [...commentBoxes];
    updatedCommentBoxes[index].position = { x, y };
    setCommentBoxes(updatedCommentBoxes);

    const image = event.target;

    localStorage.setItem(
      "commentPositions",
      JSON.stringify(updatedCommentBoxes.map((cb) => cb.position))
    );

    const commentId = updatedCommentBoxes[index].comments[0]?.id;
    const token = getToken();

    const payload = {
      comment: updatedCommentBoxes[index].comments[0].comment,
      position_x: x,
      position_y: y,
    };

    axios
      .post(
        apiUrl + `user/project/${project.id}/updatecomment/${commentId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    setDragging(false);
  };


  const addComment = (event, comment) => {
    const image = event.currentTarget;
    const rect = image.getBoundingClientRect();

    const x = event.nativeEvent.offsetX;
    const y = event.nativeEvent.offsetY;

    const showOnLeft = event.nativeEvent.offsetX > rect.width / 2;

    const newCommentBox = {
      position: { x, y },
      ...(comment && { comments: [comment] }),
      showCommentSection: true,
      newComment: true,
      showOnLeft,
    };

    const updatedCommentBoxes = commentBoxes.map((cb) => ({
      ...cb,
      showCommentSection: false,
    }));

    const filteredCommentBoxes = updatedCommentBoxes.filter(
      (cb) => cb.comments && cb.comments.length > 0
    );

    setCommentBoxes([...filteredCommentBoxes, newCommentBox]);
    setCommentPosition({ x, y });
  };







  const handleCommentInput = async (event) => {
    const comment = event.target.value || commentValue;
    const addCommentToAPI = async (comment, position) => {
      const token = getToken();
      const payload = {
        comment: comment,
        position_x: position?.x,
        position_y: position?.y,
        screens_id: currentScreen?.id,
        commenttype_id: commenttype_id,
      };

      try {
        const response = await axios.post(
          apiUrl + `user/project/${project?.id}/comment`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data);
        fetchComments();
        setCommentValue("");
      } catch (error) {
        console.log(error);
      }
    };

    const updatedCommentBoxIndex = commentBoxes.findIndex(
      (cb) =>
        cb.position.x === commentPosition.x &&
        cb.position.y === commentPosition.y
    );
    setLastUpdatedBox(updatedCommentBoxIndex);

    const isEnterKey = event.key === "Enter";
    const isButtonClick = event.target.id === "comm-post";

    if (isButtonClick || isEnterKey) {
      addCommentToAPI(comment, commentPosition);

      const updatedCommentBoxes = commentBoxes.map((cb) =>
        cb.position.x === commentPosition.x &&
          cb.position.y === commentPosition.y
          ? { ...cb, newComment: false, showCommentSection: true }
          : cb
      );
      setCommentBoxes(updatedCommentBoxes);
    }
  };

  // const getImageCursor = () => {
  //   return activeIndex === 0 ? "crosshair" : "default";
  // };
  const handleCommentToggle = (index) => {
    const clickedCommentBox = commentBoxes[index];
    setCommentPosition(clickedCommentBox.position);
    setCommentBoxes((prevCommentBoxes) =>
      prevCommentBoxes.map((cb, idx) => ({
        ...cb,
        showCommentSection: idx === index ? !cb.showCommentSection : false,
      }))
    );
  };

  const handleDeleteComment = (commId) => {
    const url = apiUrl + `user/project/${project?.id}/comment/${commId}`;
    axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          fetchComments();
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchComments();
  }, [currentScreen?.id, project?.id]);


  const scImg = project?.screens?.map((t) => t.screen_url) || [];

  useEffect(() => {
    // Check if there are screens available
    if (scImg.length > 0) {
      const initialScreen = project?.screens[0];
      setCurrentScreen(initialScreen);

    }
  }, [project?.screens, scImg.length]);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    const prevScreen = project?.screens[currentImageIndex - 1];
    if (prevScreen) {
      const { name, id } = prevScreen;
      setCurrentScreen(prevScreen);
      navigate(`/shareProject/${link}/${name}/${id}`);
    }
  };

  const handleNextImage = () => {
    const nextIndex = currentImageIndex < scImg.length - 1 ? currentImageIndex + 1 : currentImageIndex;
    setCurrentImageIndex(nextIndex);
    const nextScreen = project?.screens[nextIndex];
    if (nextScreen) {
      const { name, id } = nextScreen;
      setCurrentScreen(nextScreen);
      navigate(`/shareProject/${link}/${name}/${id}`);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        handlePrevImage();
      } else if (event.key === 'ArrowRight') {
        handleNextImage();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentImageIndex, handlePrevImage, handleNextImage]);


  return (
    <div className='screen-full flex'>

      {currentScreen ? (
        <div className="rez" style={{ position: "relative" }}>
          <img
            className="sc-prv-full"
            src={currentScreen.screen_url}
            onClick={(event) => addComment(event, commentValue)}
            // style={{ cursor: getImageCursor() }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          />

          <>
            {commentBoxes?.map((commentBox, index) => {
              const position = commentBox.position;
              let firstCommentNumber = null;
              commentBox?.comments?.forEach((comment) => {
                if (firstCommentNumber === null) {
                  firstCommentNumber = comment.number;
                }
              });

              const imageElement = document.querySelector(".sc-prv-full");
              const renderedImageWidth = imageElement ? imageElement.clientWidth : 0;
              const renderedImageHeight = imageElement ? imageElement.clientHeight : 0;


              return (
                <>

                  <div
                    className="new-comm flex"
                    key={index}
                    draggable
                    onDragStart={(event) =>
                      handleDragStart(event, index)
                    }
                    onClick={(event) => {
                      event.stopPropagation();
                      handleCommentToggle(index);
                    }}
                    style={{
                      position: "absolute",
                      left: `calc(${(position.x / renderedImageWidth) * 100}% - 12px)`,
                      top: `calc(${(position.y / renderedImageHeight) * 100}% - 12px)`,

                      transition: dragging ? "none" : "left 0.2s, top 0.2s",
                    }}
                  >
                    <div className="new-comm-inner flex">
                      <p id="c-nr">{firstCommentNumber}</p>
                      <div className="msnn"></div>
                      {commentBox.showCommentSection && (
                        <div
                          className="comment-section"
                          onClick={(e) => e.stopPropagation()}
                          style={{
                            position: "absolute",
                            zIndex: "15",
                            left: commentBox.showOnLeft
                              ? "auto"
                              : "-2px",
                            right: commentBox.showOnLeft
                              ? "-2px"
                              : "auto",
                          }}
                        >
                          <div className="comm-sec-inner">
                            <RiArrowUpSFill
                              id="upp"
                              style={{
                                position: "absolute",
                                left: commentBox.showOnLeft
                                  ? "auto"
                                  : "3px",
                                right: commentBox.showOnLeft
                                  ? "3px"
                                  : "auto",
                              }}
                            />

                            {commentBox?.comments?.length > 0 ? (
                              <>
                                <div className="comm-inf flex-b">
                                  <p>Mark as resolved</p>
                                  <GrClose
                                    id="comm-close"
                                    onClick={() => {
                                      handleCommentToggle(index);
                                    }}
                                  />
                                </div>

                                {commentBox?.comments?.map(
                                  (comment, index) => {
                                    const currentDate = new Date();
                                    const createdAtDate = new Date(
                                      comment.created_at
                                    );
                                    if (
                                      currentDate.getTime() -
                                      createdAtDate.getTime() <=
                                      604800000
                                    ) {
                                      // 7 days in milliseconds
                                      const formattedDate = "now";
                                    } else {
                                      const options = {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                      };
                                      const formattedDate =
                                        createdAtDate.toLocaleDateString(
                                          "en-US",
                                          options
                                        );
                                      const optionsTime = {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      };
                                      const formattedTime =
                                        createdAtDate.toLocaleTimeString(
                                          "en-US",
                                          optionsTime
                                        );
                                    }

                                    return (
                                      <div
                                        className="comments-showed flex-c"
                                        key={index}
                                      >
                                        <div className="comment flex">
                                          <div className="cm-user"></div>

                                          <div className="cm-infos">
                                            <div className="cm-infos-top flex">
                                              {user ? (
                                                <h4>{user?.name}</h4>
                                              ) : (
                                                <h4>No User</h4>
                                              )}
                                              <p>
                                                {" "}
                                                {currentDate.getTime() -
                                                  createdAtDate.getTime() <=
                                                  604800000 ? (
                                                  "now"
                                                ) : (
                                                  <>
                                                    {createdAtDate.toLocaleDateString(
                                                      "en-US",
                                                      {
                                                        day: "2-digit",
                                                        month:
                                                          "2-digit",
                                                        year: "numeric",
                                                      }
                                                    )}{" "}
                                                    {createdAtDate.toLocaleTimeString(
                                                      "en-US",
                                                      {
                                                        hour: "2-digit",
                                                        minute:
                                                          "2-digit",
                                                        hour12: true,
                                                      }
                                                    )}
                                                  </>
                                                )}
                                              </p>
                                              <FiEdit
                                                id="edit"
                                                onClick={() =>
                                                  handleEditComment(
                                                    comment.id
                                                  )
                                                }
                                              />
                                              <RiDeleteBinLine
                                                onClick={() =>
                                                  handleDeleteComment(
                                                    comment.id
                                                  )
                                                }
                                              />
                                            </div>
                                            {editingComments[
                                              comment.id
                                            ] && (
                                                <div className="edit-cmm">
                                                  <input
                                                    type="text"
                                                    placeholder={
                                                      comment.comment
                                                    }
                                                    value={editedComment}
                                                    onChange={(event) =>
                                                      setEditedComment(
                                                        event.target.value
                                                      )
                                                    } // Update the editedComment state
                                                  />
                                                  <div className="edt-btns flex">
                                                    <button
                                                      onClick={() =>
                                                        setEditingComments(
                                                          false
                                                        )
                                                      }
                                                      className="edt-cnc"
                                                    >
                                                      Cancel
                                                    </button>
                                                    <button
                                                      onClick={() =>
                                                        handleSaveComment(
                                                          comment?.id,
                                                          editedComment
                                                        )
                                                      }
                                                    >
                                                      Save
                                                    </button>
                                                  </div>
                                                </div>
                                              )}
                                            <p>{comment.comment}</p>
                                          </div>
                                        </div>

                                        {comment.childs?.length > 0 && (
                                          <>
                                            {comment.childs?.map(
                                              (m) => {
                                                const currentDate =
                                                  new Date();
                                                const createdAtDate =
                                                  new Date(
                                                    comment.created_at
                                                  );
                                                if (
                                                  currentDate.getTime() -
                                                  createdAtDate.getTime() <=
                                                  604800000
                                                ) {
                                                  const formattedDate =
                                                    "now";
                                                } else {
                                                  const options = {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                  };
                                                  const formattedDate =
                                                    createdAtDate.toLocaleDateString(
                                                      "en-US",
                                                      options
                                                    );
                                                  const optionsTime = {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    hour12: true,
                                                  };
                                                  const formattedTime =
                                                    createdAtDate.toLocaleTimeString(
                                                      "en-US",
                                                      optionsTime
                                                    );
                                                }
                                                return (
                                                  <div className="comment flex">
                                                    <div className="cm-user"></div>

                                                    <div className="cm-infos">
                                                      <div className="cm-infos-top flex">
                                                        {user ? (
                                                          <h4>
                                                            {user?.name}
                                                          </h4>
                                                        ) : (
                                                          <h4>
                                                            No User
                                                          </h4>
                                                        )}
                                                        <p>
                                                          {" "}
                                                          {currentDate.getTime() -
                                                            createdAtDate.getTime() <=
                                                            604800000 ? (
                                                            "now"
                                                          ) : (
                                                            <>
                                                              {createdAtDate.toLocaleDateString(
                                                                "en-US",
                                                                {
                                                                  day: "2-digit",
                                                                  month:
                                                                    "2-digit",
                                                                  year: "numeric",
                                                                }
                                                              )}{" "}
                                                              {createdAtDate.toLocaleTimeString(
                                                                "en-US",
                                                                {
                                                                  hour: "2-digit",
                                                                  minute:
                                                                    "2-digit",
                                                                  hour12: true,
                                                                }
                                                              )}
                                                            </>
                                                          )}
                                                        </p>
                                                        <FiEdit
                                                          id="edit"
                                                          onClick={() =>
                                                            handleEditComment(
                                                              m.id
                                                            )
                                                          }
                                                        />
                                                        <RiDeleteBinLine
                                                          onClick={() =>
                                                            handleDeleteComment(
                                                              m.id
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      {editingComments[
                                                        m.id
                                                      ] && (
                                                          <div className="edit-cmm">
                                                            <input
                                                              type="text"
                                                              placeholder={
                                                                m.comment
                                                              }
                                                              onChange={(
                                                                event
                                                              ) =>
                                                                setEditedComment(
                                                                  event
                                                                    .target
                                                                    .value
                                                                )
                                                              } // Update the editedComment state
                                                            />
                                                            <div className="edt-btns flex">
                                                              <button
                                                                onClick={() =>
                                                                  setEditingComments(
                                                                    false
                                                                  )
                                                                }
                                                                className="edt-cnc"
                                                              >
                                                                Cancel
                                                              </button>
                                                              <button
                                                                onClick={() =>
                                                                  handleSaveComment(
                                                                    m?.id,
                                                                    editedComment
                                                                  )
                                                                }
                                                              >
                                                                Save
                                                              </button>
                                                            </div>
                                                          </div>
                                                        )}
                                                      <p>{m.comment}</p>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </>
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <div className="comm-top flex-b">
                                <div className="comm-type flex">
                                  <FiUnlock id="unlock" />
                                  <p>Public Comment</p>
                                  <MdOutlineKeyboardArrowDown />
                                </div>

                                <GrClose
                                  id="comm-close"
                                  onClick={() => {
                                    handleCommentToggle(index);
                                  }}
                                />
                              </div>
                            )}

                            <div className="add-comm">
                              <div className="adc-user"></div>

                              <div className="add-comm-area">
                                <input
                                  type="text"
                                  placeholder="Add a comment"
                                  className="comment-input"
                                  onKeyDown={handleCommentInput}
                                  autoFocus
                                  value={commentValue}
                                  onChange={(event) =>
                                    setCommentValue(event.target.value)
                                  }
                                />

                                <div className="adc-tools flex">
                                  <BsEmojiSmile id="smile" />
                                  <MdOutlineAttachFile id="attach" />
                                </div>
                              </div>
                            </div>

                            <div className="comm-bottom flex-b">
                              <div className="scbr-bg-clrs flex">
                                <div
                                  onClick={() => handleMode(0)}
                                  className="scbr-bg-clr c1"
                                >
                                  <div
                                    className="outl flex"
                                    style={{
                                      border:
                                        mode === 0
                                          ? "1px solid #4280EB"
                                          : "1px solid transparent",
                                    }}
                                  ></div>
                                </div>
                                <div
                                  onClick={() => handleMode(1)}
                                  className="scbr-bg-clr c2"
                                >
                                  <div
                                    className="outl flex"
                                    style={{
                                      border:
                                        mode === 1
                                          ? "1px solid #E3244B"
                                          : "1px solid transparent",
                                    }}
                                  ></div>
                                </div>

                                <div
                                  onClick={() => handleMode(2)}
                                  className="scbr-bg-clr c3"
                                >
                                  <div
                                    className="outl flex"
                                    style={{
                                      border:
                                        mode === 2
                                          ? "1px solid #FFB341"
                                          : "1px solid transparent",
                                    }}
                                  ></div>
                                </div>
                              </div>

                              <button
                                onClick={() =>
                                  handleCommentInput(
                                    { target: { id: "comm-post" } },
                                    commentValue
                                  )
                                }
                                id="comm-post"
                              >
                                Post
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                </>
              );
            })}
          </>
        </div>
      ) : (
        "Loading..."
      )}
      {project?.screens?.length == 1 ? ('') : (
        <>
          <button className='sc-b sc-prev' onClick={handlePrevImage}><MdKeyboardArrowLeft /></button>
          <button className='sc-b sc-next' onClick={handleNextImage}><MdKeyboardArrowRight /></button>
        </>
      )}
      <div className='sc-bb sc-prv-more'><FiMoreHorizontal /></div>
      {project?.screens?.length > 0 && (
        <div className='sc-bb sc-prv-comm'><BiCommentDetail /></div>
      )}
      <div className='sc-prev-logo2'>
        <div className='spl2'></div>
      </div>
    </div>
  );

}

export default ProjectPrev