import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { Tab, Tabs, TabPanel, TabList } from 'react-tabs'
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import './nav.scss'
import { IoResize } from 'react-icons/io5'
import { AiFillHome, AiOutlinePlus, AiFillMessage } from 'react-icons/ai'
import { BiSearch, BiFilter } from 'react-icons/bi'
import { GiQueenCrown } from 'react-icons/gi'
import { RiArrowDownSFill } from 'react-icons/ri'
import { CgMenuGridR } from 'react-icons/cg'
import { IoNotificationsSharp } from 'react-icons/io5'
import { FiMenu } from 'react-icons/fi'
import UserMenu from './UserMenu/UserMenu';
import New from './New/New';
import { MdKeyboardArrowDown } from 'react-icons/md'
import PersonalDrop from './PersonalDrop/PersonalDrop';
import apiUrl from '../../../api';
import ComBox from './ComBox/ComBox';


const Nav = ({ isOpen = { isOpenNav: false, isOpenSideMenu: false }, toggleSidebar, projects }) => {

    const [isSticky, setIsSticky] = useState(false);
    const [search, setSearch] = useState('');
    const [openUser, setOpenUser] = useState(false)
    const [openNew, setNew] = useState(false)
    const [openSearch, setOpenSearch] = useState(false)
    const [openPers, setOpenPers] = useState(false)
    const [selectedPers, setSelectedPers] = useState(null);
    const [user, setUser] = useState(null);
    const [comBox, setComBox] = useState(null);

    const [searchResults, setSearchResults] = useState([]);
    const location = useLocation();

    const handleSearch2 = (query) => {
        const filteredProjects = projects?.filter(project =>
            project.project_title.toLowerCase().includes(query.toLowerCase())
        );
        setSearchResults(filteredProjects);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            // Redirect to search results page
            window.location.href = `/Dashboard/Search/${search}`;
        }
    };

    const handleToggle = () => {
        toggleSidebar()
    }


    function getToken() {
        return localStorage.getItem('token');
    }

    const axiosInstance = axios.create();

    axiosInstance.interceptors.request.use(
        (config) => {
            const token = getToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axiosInstance.get(apiUrl + 'user/me');
                const userData = response.data;
                setUser(userData);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // handle unauthorized error
                } else {
                    console.log(error);
                }
            }
        };

        fetchUserData();
    }, []);


    const handlePers = () => {
        setOpenPers(!openPers);
    }
    const handlePersSelect = (pers) => {
        setSelectedPers(pers);
        setOpenPers(false);
    };

    useEffect(() => {
        const nav = document.querySelector(".dashboard-nav");
        const navHeight = nav.scrollHeight;

        const handleScroll = () => {
            const scrollHeight = window.pageYOffset;
            setIsSticky(scrollHeight > 20 ? true : false);
        };

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleClickUser = () => {
        setOpenUser(!openUser)
    }
    const handleClickNew = (e) => {
        e.preventDefault()
        setNew(!openNew)
    }
    const handleSearch = () => {
        setOpenSearch(!openSearch)
    }

    const handleSidebarToggle = () => {
        toggleSidebar('nav');
    };

    const isAccountSettingsPage = location.pathname.includes('/Dashboard/AccountSettings');
    const upgr = location.pathname.includes('/Dashboard/Upgrade');


    let srcccVisible = false;

    const handleSrc = (e) => {
        e.stopPropagation();
        const srccc = document.getElementById("srccc");

        if (srccc) {
            if (srcccVisible) {
                srccc.style.display = "none";
            } else {
                srccc.style.display = "flex";
                srccc.style.position = "fixed";
            }

            srcccVisible = !srcccVisible;
        }
    };

    const searchIcon = document.getElementById("sep-src");
    if (searchIcon) {
        searchIcon.addEventListener("click", handleSrc);
    }

    const handleCom = () => {
            setComBox(!comBox)
    }
    return (

       
        <div id="dashboard-nav flex" className={clsx(`dashboard-nav `, isSticky ? "dashboard-nav__sticky" : "")} >
            <div className="left-nav flex">
                <button className='menu-button' onClick={handleSidebarToggle}>
                    <FiMenu />
                </button>
                {isOpen.isOpenNav &&
                    <Link to='/Dashboard/Overview'>
                        {isOpen.isOpenSideMenu ? (
                            <div className="logo"></div>

                        ) : (
                            <div className="logo-2"></div>
                        )

                        }
                    </Link>
                }

            </div>


            <div className="inner-nav2 flex">
                <Link to='/'><AiFillHome /></Link> /

                {isAccountSettingsPage ? (
                    <>
                        <Link to='/dashboard/AccountSettings'>Settings</Link> <span>/</span>
                        <Link to='/dashboard/AccountSettings'>Account</Link>
                        {upgr && (
                            <>
                                <Link to='/Dashboard/Upgrade'><FormattedMessage id='upgrade' defaultMessage='Upgrade' /></Link>

                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Link to='/dashboard/overview'><FormattedMessage id='overview' defaultMessage='Overview' /></Link> <span>/</span>
                        {upgr && (
                            <>
                                <Link to='/Dashboard/Upgrade'><FormattedMessage id='upgrade' defaultMessage='Upgrade' /></Link>

                            </>
                        )}
                    </>
                )}
                <div className="select-container flex" onClick={handlePers}>
                    <div className="personal-icon"></div>
                    <span>{selectedPers || 'Personal'}</span>
                    <MdKeyboardArrowDown />
                    {openPers &&
                        (<PersonalDrop
                            handlePersSelect={handlePersSelect}
                            setOpenPers={setOpenPers}
                        ></PersonalDrop>)
                    }
                </div>
            </div>

            <div className="inner-nav3 flex">
                <div id='srccc' className="search-container flex-b">
                    <span className='flex'><BiSearch /></span>
                    <input
                        onClick={handleSearch}
                        type='search'
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                            handleSearch2(e.target.value);
                        }}
                        onKeyDown={handleKeyPress}
                        name='nav-search'
                        id='nav-search'
                        placeholder='Search'
                    />
                    <button className='flex'><BiFilter /></button>

                    {openSearch &&
                        <Tabs className='search-items'>
                            <div className="src-top flex-b">
                                <p>Search</p>
                                <div className="max flex">
                                    <IoResize />
                                </div>
                            </div>
                            <TabList className='src-list flex'>
                                <Tab className='src-tab'>Projects</Tab>
                                <Tab className='src-tab'>Folders</Tab>
                                <Tab className='src-tab'>Teams</Tab>
                                <Tab className='src-tab'>Comments</Tab>
                            </TabList>



                            <TabPanel>
                                <div className="sri-inner">
                                    {searchResults?.slice(0, 4).map(project => (
                                        <div className='search-item' key={project.id}>
                                            <div className="src-iconn flex">
                                                <div className="src-icon" style={{ background: `url(${project?.cover_url_thumb})` }}></div>
                                            </div>
                                            <div className="src-res">
                                                <Link to={`/Dashboard/Screens/${project.id}/${project.project_title}`}>{project.project_title}</Link>
                                                <Link className='src-lk' to='/Dashboard/Projects'>All Projects</Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </TabPanel>

                            <div className="src-btm flex">
                                <BiSearch />
                                <Link to={`/Dashboard/Search/${search}`}>View all results for <span>'{search}'</span></Link>
                            </div>


                        </Tabs>
                    }

                </div>
                <BiSearch className='nav-srch' onClick={handleSearch} />
            </div>

            <div className="right-nav flex">
                <Link to='/Dashboard/Upgrade' className='upgrade flex'>
                    <GiQueenCrown />
                    <FormattedMessage id='upgrade' defaultMessage='Upgrade' />
                </Link>

                <div className="ov-new flex-b" onClick={handleClickNew}>
                    <button className='plus flex' >
                        <AiOutlinePlus />
                    </button>
                    <p><FormattedMessage id='new' defaultMessage='New'></FormattedMessage></p>
                    <button className='ov-arr-down flex' onClick={handleClickNew}>
                        <RiArrowDownSFill />
                    </button>

                    {openNew && (
                        <New triger={openNew} setTriger={setNew}></New>
                    )}
                </div>

                <button className='ov-message flex'>
                    <AiFillMessage />
                </button>

                <button className='ov-ring flex' onClick={handleCom}>
                    <IoNotificationsSharp />
                    {comBox && (<ComBox/>)}
                </button>

                <button className="ov-menu flex">
                    <CgMenuGridR />
                </button>

                <BiSearch id='sep-src' onClick={handleSrc} />

                {user ? (
                    <div className="ov-user flex" onClick={handleClickUser}>
                        <p>{user.name}</p>
                        <div className="user-icon"></div>
                        <button className='user-drop flex' >
                            <RiArrowDownSFill />
                            {openUser && (
                                <UserMenu user={user} triger={openUser} setTrigger={setOpenUser}></UserMenu>
                            )}
                        </button>
                    </div>
                ) : ('')}


            </div>

        </div>
    )
}

export default Nav