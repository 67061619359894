import React from 'react'
import './PrivateDrop.scss'

const PrivateDrop = ({handleProSelect, setOpenPro}) => {

  const handleItemClick = (pro) => {
    handleProSelect(pro)
    setOpenPro(false)
  }
  return (
    <div className='direct-drop1'>
          <p onClick={() => handleItemClick('Private Project')}>Private Project</p>
          <p onClick={() => handleItemClick('Public Project')}>Public Project</p>
    </div>
  )
}

export default PrivateDrop