import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { RiArrowDownSFill } from 'react-icons/ri'

import './Status.scss'


const Status = () => {
    const [busy, setBusy] = useState(false)
    const [text, setText] = useState(false)

    const handleCheck = (e) => {
        e.stopPropagation()
        setBusy(!busy)
    }
    const handleAll = (e) => {
        e.stopPropagation()
    }
    console.log(busy)
    return (
        <div className='status-container' onClick={(e) => handleAll(e)} data-aos='fade-up' data-aos-duration='400'>
            <div className="status-top flex-b">
                <h4><FormattedMessage id='set-status' defaultMessage='Set Status'></FormattedMessage></h4>
                <div className="remove-status flex">
                    <button className='remove flex'>
                        <div className="rem-icon"></div>
                    </button>
                    <p><FormattedMessage id='remove-status' defaultMessage='Remove Status'></FormattedMessage></p>
                </div>
            </div>

            <div className="status-input flex">
                <div className="smile2 flex">
                    <div className="smile2-icon"></div>
                </div>
                <input type="text" placeholder="What's going on?" />
            </div>

            <div className="stat-check flex">
                <input type="checkbox" onClick={(e) => handleCheck(e)} />
                <div className="stat-info flex-c">
                    <h2><FormattedMessage id='busy' defaultMessage="I'm busy"></FormattedMessage></h2>
                    <p ><FormattedMessage id='busy-desc' defaultMessage="A notification will be sent to others to inform that you're not available right away."></FormattedMessage></p>
                </div>
            </div>

            <div className="setstatus">
                <div className="ss flex-b">
                    <p><FormattedMessage id='ss' defaultMessage='Set Status'></FormattedMessage></p>
                    <button className='ss-btn'>
                        <RiArrowDownSFill />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Status