import React, { useState, useRef, useEffect } from 'react'
import './DirectUpload.scss'
import { AiOutlineUpload, AiOutlineCloseCircle, AiOutlineCheck, AiOutlineArrowUp } from 'react-icons/ai'
import { HiOutlineArrowLeft } from 'react-icons/hi'
import { FormattedMessage } from 'react-intl'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { BiTimeFive } from 'react-icons/bi'
import { BsLink45Deg } from 'react-icons/bs'
import { HiOutlineArrowUp } from 'react-icons/hi'
import { BiCheck } from 'react-icons/bi'
import PrivateDrop from './PrivateDrop/PrivateDrop'
import WebsiteDrop from './WebsiteDrop/WebsiteDrop'
import axios from 'axios'
import { getToken } from '../../../../../components/pages/Auth/Token'
import apiUrl from '../../../../../api'

const DirectUpload = (props) => {
    const { handleClick2, setShowFirstForm, setShowDirectUploadForm } = props;
    const [project_title, setPrName] = useState('');
    const [projecttype, setSelectedPro] = useState(null)
    const [openPro, setOpenPro] = useState(false)
    const [projectdevice, setSelectedWeb] = useState('Web')
    const [openWeb, setOpenWeb] = useState(false)
    const [dragOver, setDragOver] = useState(false)
    const [privacy, setPrivacy] = useState(1111)
    const [isFormValid, setIsFormValid] = useState(false);
    const [files, setFiles] = useState([]);
    const [fileName, setFileName] = useState(Array(files.length).fill(''));
    const [fileSize, setFileSize] = useState(Array(files.length).fill(0));
    const [project, setProject] = useState(null);
    const [loadingTime, setLoadingTime] = useState(null);
    const [progress, setProgress] = useState(0)
    const [completedCount, setCompletedCount] = useState(0);
    const [fileSizes, setFileSizes] = useState({});
    const [remainingFiles, setRemainingFiles] = useState([]);
    const [projectTitle, setProjectTitle] = useState(null)

    useEffect(() => {
        return () => {
            // Cancel any pending requests when the component unmounts
            controller.abort();
        };
    }, []);


    const controller = new AbortController();

    const projectId = project?.id

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = getToken();
        try {
            const response = await axios.post(
                apiUrl + 'user/projects',
                {
                    project_title,
                    projecttype,
                    projectdevice,
                    privacy
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            const createdProject = response.data;
            setProject(createdProject);
            console.log(createdProject)
            const projectId = createdProject.id;
            const projectTitle = createdProject.project_title;
            await handleSubmit2(projectId);
            window.location.href = `/Dashboard/Screens/${projectId}/${projectTitle}`
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit2 = async (projectId) => {
        const fileUploadPromises = files.map(async (file, i) => {
            const formData = new FormData();
            formData.append(`file_name_${i}`, file.name);
            formData.append(`file_size_${i}`, file.size);

            try {
                const response = await fetch(
                    apiUrl + `user/project/${projectId}/fileuploadrequest`,
                    {
                        headers: {
                            Authorization: `Bearer ${getToken()}`
                        },
                        method: 'POST',
                        body: formData,
                        signal: controller.signal
                    }
                );
                const data = await response.json();
                const uploadUrl = data.data.upload_url;
                await handleSubmit3(uploadUrl, file);
            } catch (error) {
                console.error('Error uploading file:', file, error);
            }
        });

        try {
            await Promise.all(fileUploadPromises);
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };


    const handleSubmit3 = async (uploadUrl, file) => {
        if (!uploadUrl) {
            console.error('Error: uploadUrl is not available');
            return;
        }
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(uploadUrl, {
                method: 'POST',
                body: formData,
            });
            // Handle the response if needed
        } catch (error) {
            console.error('Error uploading file:', file, error);
        }
    };


    console.log('projekti', projectId);

    const handleCreatetClick = () => {
        if (project_title === '') {
            alert('Please enter project name');
            setIsFormValid(false);
            return;
        }
    }
    const handlePro = () => {
        setOpenPro(!openPro)
    }
    const handleProSelect = (pro) => {
        setSelectedPro(pro);
        setOpenPro(false);
    };


    const handleWebClick = () => {
        setOpenWeb(!openWeb)
    }

    const handleWebSelect = (web) => {
        setSelectedWeb(web);
        setOpenWeb(false);
    };

    const handleClick = (e) => {
        e.preventDefault()
        setShowDirectUploadForm(false)
        setShowFirstForm(true)
    }

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragOver(true);
        event.currentTarget.classList.add('circle-border');
    };

    const handleDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragOver(false);
        event.currentTarget.classList.remove('circle-border');
    };

    const formatFileSize = (bytes) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const droppedFiles = Array.from(event.dataTransfer.files);
        setFiles([...files, ...droppedFiles]);
        setRemainingFiles([...files, ...droppedFiles]);
        uploadFiles(droppedFiles);
    };

    const handleFileUpload = (e) => {
        e.preventDefault();
        const fileList = e.target.files;
        setFiles([...files, ...fileList]);

        const newFiles = Array.from(fileList);
        const newFileNames = newFiles.map((f) => f.name);
        const newFileSizes = newFiles.map((f) => f.size);

        setFileName([...fileName, ...newFileNames]);
        setFileSize([...fileSize, ...newFileSizes]);

        setRemainingFiles([...files, ...fileList]);
        uploadFiles(newFiles);
    };

    const uploadFiles = (files) => {
        // Measure the time it takes to load each file
        files.forEach((file) => {
            const startTime = performance.now();
            const reader = new FileReader();
            reader.onloadend = () => {
                const endTime = performance.now();
                const loadingTime = (endTime - startTime).toFixed(2);
                setLoadingTime((prevLoadingTimes) => ({
                    ...prevLoadingTimes,
                    [file.name]: `${loadingTime} ms`,
                }));
            };
            setFileSizes((prevFileSizes) => ({
                ...prevFileSizes,
                [file.name]: formatFileSize(file.size),
            }));
            reader.readAsDataURL(file);
        });

        // Upload each file using XMLHttpRequest
        files.forEach((file) => {
            const xhr = new XMLHttpRequest();
            xhr.open("GET", URL.createObjectURL(file), true);
            xhr.responseType = "blob";

            xhr.onload = () => {
                setCompletedCount((prevCount) => prevCount + 1);
                if (xhr.readyState === xhr.DONE) {
                    if (xhr.status === 200) {
                        setProgress((prevProgress) => ({
                            ...prevProgress,
                            [file.name]: ((xhr.response.size / file.size) * 100).toFixed(0),
                        }));
                    }
                }
            };

            xhr.onerror = () => {
                setCompletedCount((prevCount) => prevCount + 1);
                console.error(`Error uploading ${file.name}: ${xhr.statusText}`);
            };

            xhr.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentComplete = (event.loaded / event.total) * 100;
                    setProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: percentComplete.toFixed(0),
                    }));
                }
            };
            xhr.send();
        });
    };

    const fileInputRef = useRef(null);
    const handleClickUpload = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };


    const handleClearFiles = () => {
        setFiles([])
    }

    const handleDeleteFile = (file) => {
        const updatedFiles = files.filter((f) => f !== file);
        setFiles(updatedFiles);
        setRemainingFiles(updatedFiles);
    };
    return (

        <div className='dp-container' data-aos='zoom-in' data-aos-duration="400">
            <form onSubmit={handleSubmit}>
                <div className="npr-top flex">
                    <div className="dpr-arr flex" onClick={handleClick}>
                        <HiOutlineArrowLeft />
                    </div>
                    <p><FormattedMessage id='npr-add' defaultMessage='Add New Project'></FormattedMessage></p>
                    <AiOutlineCloseCircle onClick={handleClick2} />
                </div>

                <div className="npr-name">
                    <p><FormattedMessage id='project-name' defaultMessage='Project name'></FormattedMessage></p>
                    <div className="npr-name-area flex">
                        <div className="nprname-icon"></div>
                        <input
                            type="text"
                            id='pr-name'
                            name='pr-name'
                            value={project_title}
                            onChange={(e) => setPrName(e.target.value)}
                            placeholder='Write project name here'
                        />
                    </div>
                </div>

                <div className="dp-drops flex">
                    <div className="dp-projects dp-drp">
                        <span>Project Type</span>
                        <div className="dp-prj dp-inner flex-b" onClick={handlePro}>
                            <div className="dpprj-icon"></div>
                            <p>{projecttype || "Private Project"}</p>
                            <MdKeyboardArrowDown />
                            {openPro && <PrivateDrop
                                handleProSelect={handleProSelect}
                                setOpenPro={setOpenPro}
                            ></PrivateDrop>}
                        </div>
                    </div>

                    <div className="dp-prototype dp-drp" >
                        <span>Prototype</span>
                        <div className="dp-prt dp-inner flex-b" onClick={handleWebClick}>
                            <div className="dpprt-icon"></div>
                            <p>{projectdevice || "Website"}</p>
                            <MdKeyboardArrowDown />
                            {openWeb && <WebsiteDrop
                                setOpenWeb={setOpenWeb}
                                handleWebSelect={handleWebSelect}
                            ></WebsiteDrop>}
                        </div>
                    </div>


                </div>

                <div className="dp-upload">
                    <span>Upload Screens</span>
                    <div className="upload-area flex-c"
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        style={{ border: dragOver ? '2px dashed #4280EB' : '2px dashed #cecece' }}

                    >
                        <p>Drag and Drop files or click Upload to browse from your computer.</p>
                        <label htmlFor="file-input">
                            <button className="flex" onClick={handleClickUpload}>
                                <AiOutlineUpload />
                                <p>Upload</p>
                            </button>
                            <input
                                type="file"
                                id="file-input"
                                ref={fileInputRef}
                                onChange={handleFileUpload}
                                multiple
                                style={{ display: "none" }}
                            />
                        </label>

                    </div>

                    {files.length > 0 && (
                        <div className="selected-files">
                            {files.map((file, index) => (
                                <div className="file-item2 flex-b" key={index}>
                                    <div className="fi-1 flex">
                                        {progress < 100 ? (
                                            <HiOutlineArrowUp id="upp" />
                                        ) : (<BiCheck id="check" />)}
                                        <img src={URL.createObjectURL(file)} alt={file.name} />
                                        {/* <p id='f-name'>{`${projectTitle} - ${file.name}`}</p> */}


                                    </div>

                                    <div className='prt2 flex'>

                                        <span className='flex'>
                                            <BiTimeFive />
                                            <div className="f-tm">
                                                {loadingTime && (
                                                    <p>{loadingTime[file.name]}</p>
                                                )}
                                            </div>

                                            {fileSizes && (
                                                <p>{fileSizes[file.name]}</p>
                                            )}

                                        </span>
                                        <div className="prg ">

                                            {progress[file.name]?.length && (
                                                <progress value={progress[file.name]} />
                                            )}

                                        </div>
                                        <div className="prg-cont flex">
                                            <p>{completedCount ? "100%" : ""}</p>
                                            <BsLink45Deg />

                                            <AiOutlineCloseCircle id='f-cls' onClick={() => handleDeleteFile(file)}
                                            />
                                        </div>
                                    </div>



                                </div>

                            ))}
                        </div>
                    )}
                </div>


                <div className='npr-bottom flex'>
                    <p onClick={handleClearFiles}>Clean All Uploads</p>
                    <button id='pr-cancel' onClick={handleClick2}>Cancel</button>
                    <button onClick={handleCreatetClick} type='submit' id='pr-next'>Create Project</button>
                </div>
            </form>
        </div>
    )
}

export default DirectUpload