import { FormattedMessage } from 'react-intl'
import icon1 from '../../../assets/Dashboard/Overview/Sidebar/icon1.png'
import icon2 from '../../../assets/Dashboard/Overview/Sidebar/icon2.png'
import icon3 from '../../../assets/Dashboard/Overview/Sidebar/icon3.png'
import icon4 from '../../../assets/Dashboard/Overview/Sidebar/icon4.png'
import icon5 from '../../../assets/Dashboard/Overview/Sidebar/icon5.png'
import icon6 from '../../../assets/Dashboard/Overview/Sidebar/icon6.png'
import icon7 from '../../../assets/Dashboard/Overview/Sidebar/icon7.png'
import icon8 from '../../../assets/Dashboard/Overview/Sidebar/icon8.png'
import icon9 from '../../../assets/Dashboard/Overview/Shares/share-alt.png'


export const subMenuData =
    [
        { id: 'last-uploads', title: 'Last Uploads', to: '/Dashboard/Projects/LastUploads' },
        { id: 'remote-uploads', title: 'Remote Uploads', to: '/Dashboard/Projects/RemoteUploads' },
        { id: 'ftp-uploads', title: 'FTP Uploads', to: '/Dashboard/Projects/FTPUploads' },
    ]


export const mainData = [
    {
        id: 1,
        icon: icon1,
        to: '/Dashboard/Overview',
        title: <FormattedMessage id='mn-1' defaultMessage='Overview'></FormattedMessage>,
    },
    {
        id: 2,
        icon: icon2,
        to: '/Dashboard/Projects',
        title: <FormattedMessage id='mn-2' defaultMessage='Projects'></FormattedMessage>,
    },
    {
        id: 3,
        icon: icon3,
        // to: '/Dashboard/Uploads',
        title: <FormattedMessage id='mn-3' defaultMessage='Uploads'></FormattedMessage>,
    },
    {
        id: 4,
        icon: icon4,
        to: '/Dashboard/Comments',
        title: <FormattedMessage id='mn-4' defaultMessage='Comments'></FormattedMessage>,
    },
    {
        id: 5,
        icon: icon5,
        to: '/Dashboard/StarredProjects',
        title: <FormattedMessage id='mn-5' defaultMessage='Starred'></FormattedMessage>,
    },
    {
        id: 9,
        icon: icon9,
        to: '/Dashboard/Shares',
        title: <FormattedMessage id='mn-5' defaultMessage='Shares'></FormattedMessage>,
    },
    {
        id: 6,
        icon: icon6,
        to: '',
        title: <FormattedMessage id='mn-6' defaultMessage='Tasks'></FormattedMessage>,
    },
    {
        id: 7,
        icon: icon7,
        to: '',
        title: <FormattedMessage id='mn-7' defaultMessage='Analytics'></FormattedMessage>,
    },
    {
        id: 8,
        icon: icon8,
        to: '',
        title: <FormattedMessage id='mn-8' defaultMessage='Help'></FormattedMessage>,
    },
]