import React, { useEffect, useState} from "react";
import './ComBox.scss'
import { getToken } from "../../../../components/pages/Auth/Token";
import apiUrl from "../../../../api";
import axios from "axios";
import { IoSettingsOutline } from "react-icons/io5";
import { IoIosMore } from "react-icons/io";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { BiFilterAlt } from "react-icons/bi";
import BoxComments from "./BoxComments/BoxComments";
import { Link } from "react-router-dom";
import { RiArrowUpSFill } from "react-icons/ri";

const ComBox = () => {
    const [comm, setComm] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    apiUrl + `user/project-comments`,
                    {
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                        },
                    }
                );

                const newProjects = response.data;
                setComm(newProjects.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);


    return (
        <div className="nav-com-box" data-aos='fade-up' data-aos-duration='300' onClick={(e) => e.stopPropagation()}>
            <div className="nac-comm-inner">
                <RiArrowUpSFill id="nto-arr"/>
                <div className="nav-comm-top flex-b">
                    <h4>Notifications</h4>

                    <div className="nav-top-tools">
                        <IoSettingsOutline />
                        <IoIosMore />
                    </div>
                </div>

                <Tabs className='tn-tabs'>
                    <TabList className='tn-list flex'>
                        <Tab className='tn-tab'>All</Tab>
                        <Tab className='tn-tab'>Personal</Tab>
                        <Tab className='tn-tab'>All Teams</Tab>
                        <BiFilterAlt id="tn-flt"/>
                    </TabList>

                    <TabPanel>
                        <BoxComments comm={comm}/>
                    </TabPanel>
                    <TabPanel>
                    <BoxComments comm={comm}/>
                    </TabPanel>
                    <TabPanel>
                    <BoxComments comm={comm}/>
                    </TabPanel>
                </Tabs>

                <Link to='' className="flex nto-link">Notification Center</Link>
            </div>
        </div>
    )
}

export default ComBox