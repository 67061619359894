import React, { useEffect, useState, useCallback } from 'react'
import './ProjectsSlider.scss'
import Carousel from 'react-elastic-carousel'
import { FiMoreHorizontal } from 'react-icons/fi'
import { BsShare } from 'react-icons/bs';
import { Link } from 'react-router-dom'
const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 470, itemsToShow: 2 },
    { width: 800, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 }
];


const ProjectsSlider = ({ projects }) => {



    console.log('projektet', projects);

    const carouselRef = React.useRef(null);
    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(0);
        }
    };
    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo('single-slide'.length);
        }
    };


    return (
        <div className='ps-slider'>

            <Carousel className='prc-slider'
                breakPoints={breakPoints}
                ref={carouselRef}
                onPrevStart={onPrevStart}
                onNextStart={onNextStart}
                disableArrowsOnEnd={false}
            >
                {projects?.length > 0 ? (projects.slice(0, 10).map((p) => {
                    const date = new Date(p.created_at);
                    const options = { weekday: 'long', hour: 'numeric', minute: 'numeric', hour12: true };
                    const formattedDate = date.toLocaleString('en-US', options);
                    return (
                        <div key={p.id} className='prj-item'>
                            <div className="prj-inner">
                                <div className="prj-img" >
                                    <div className="prj-hover flex-c" data-aos='zoom-in'>
                                        <div className="prj-top flex-b">
                                            <div className="prj-autors flex">
                                                {p.members?.slice(0, 3).map((m) => {
                                                    return (
                                                        <Link to={`/Dashboard/Screens/${p.id}/Safari/Roles_Permission`} key={m.id} className="prj-aut">
                                                            <div className="prj-aimg1" style={{ content: `url(${m.avatar_url})` }}></div>
                                                        </Link>


                                                    )
                                                })}

                                                {p.members && p.members.length > 3 && (
                                                    <div className="prj-aut flex">
                                                        <div className="prj-aimg1 flex">+{p.members.length - 3}</div>
                                                    </div>
                                                )}
                                            </div>

                                            <button className='flex'><FiMoreHorizontal /></button>
                                        </div>

                                        <Link className='flex' to={`/Dashboard/Screens/${p.id}/${p.project_title.replace(/ /g, '_')}`}>View</Link>

                                        <div className="prj-btm flex">
                                            <BsShare />
                                            <p>Share</p>
                                        </div>
                                    </div>
                                    <div className="prj-imgg" style={{ background: `url(${p.cover_url_thumb})` }}></div>
                                </div>
                                <div className="prj-content flex-b">
                                    <div className="prj-info">
                                        <p>{p.project_title}</p>
                                        <span>{formattedDate}</span>
                                    </div>
                                    <div className="prj-sc flex-c">
                                        <div className="sc-icon" style={{ content: `url(${p.sc})` }}></div>
                                        <p>{p.screen_count} screens</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })) : (
                    <div className='noPrj flex-c'>
                        <div className="noprj-icon"></div>
                        <h4>You don't have any project yet.</h4>
                        <p>Share, review & approve designs, videos & documents in minutes with HostDesign.</p>
                        <button className='noprj-btn flex'>Create your first project</button>
                    </div>
                )}
            </Carousel>

        </div>
    )
}

export default ProjectsSlider