import React from 'react'
import './Drop2.scss'


const Drop2 = ({ handleAndroSelect, setDrop2 }) => {

  const handleItemClick = (andro) => {
    handleAndroSelect(andro);
    setDrop2(false);
  };

  return (
    <div className='drop2' data-aos='fade-up' data-aos-duration='400'>
      <div className="dr2-items">
        <p onClick={() => handleItemClick('Android')}>Android</p>
        <p onClick={() => handleItemClick('Samsung Galaxy S20')}>Samsung Galaxy S20</p>
        <p onClick={() => handleItemClick('Samsung Galaxy S8+')}>Samsung Galaxy S8+</p>
        <p onClick={() => handleItemClick('Galaxy Fold')}>Galaxy Fold</p>
      </div>
    </div>
  )
}

export default Drop2