import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import './ProjectPreview.scss'
import { useEffect } from 'react';
import axios from 'axios';
import apiUrl from '../../../api';
import Loading from '../../../components/shared/Loading/Loading'
const ProjectPreview = () => {
  const { link } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl + `share/project/${link}`);
        console.log("data", response.data);
        setProject(response.data);
      } catch (error) {
        console.log("error fetching data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [link]);


  if (loading) {
    return <><Loading /></>
  }


  return (
    <div className='pr-preview-page'>
      <div className="pr-view-log"></div>

      <p id='title'>Desktop / Web Prototype</p>

      <h1 id='name'>{project?.project_title}</h1>

      <div className="prPrev-count flex">
        <div className="prprv-icon"></div>
        <p>{project?.screen_count === 1 ? '1 screen' : `${project?.screen_count} screens`}</p>
      </div>

      <Link to={`/shareProject/${link}/${project?.project_title}/${project?.id}`} className="view-prj flex">
        View Project
      </Link>


      <div className="view-image flex">
        <div className="view-light"></div>
        <div className="view-img-inner">
        </div>
        <div className="view-thumb" style={{ background: `url(${project?.cover_url_thumb})` }}></div>

      </div>
    </div>
  )
}

export default ProjectPreview