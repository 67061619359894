import React from 'react'
import './TeamDrop.scss'


const TeamDrop = ({ handleTeamSelect, setOpenTeam }) => {

    const handleItemClick = (team) => {
        handleTeamSelect(team);
        setOpenTeam(false);
      };

  return (
    <div className='td-box'>
        <div className="td-items">
            <p onClick={() => handleItemClick('Team Golang')}>Team Golang</p>
            <p onClick={() => handleItemClick('Team Kosova')}>Team Kosova</p>
            <p onClick={() => handleItemClick('HostDesign Team')}>HostDesign Team</p>

        </div>
    </div>
  )
}

export default TeamDrop