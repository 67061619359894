import React from 'react'
import './SortDrop.scss'
import { AiOutlineFolderOpen } from 'react-icons/ai'
import { MdQueryStats } from 'react-icons/md'
import { HiOutlineUser } from 'react-icons/hi'
import { BsCalendar4Week } from 'react-icons/bs'


const SortDrop = ({ handleSortSelect, setOpenSort }) => {

    const handleItemClick = (type) => {
        handleSortSelect(type);
        setOpenSort(false);
      };

  return (
    <div className='sd-box'>
        <div className="sd-items">
        <p onClick={() => handleItemClick('Project Type')}><AiOutlineFolderOpen /> Project Type</p>
        <p onClick={() => handleItemClick('Status')}><MdQueryStats /> Status</p>
        <p onClick={() => handleItemClick('Project Owner')}><HiOutlineUser /> Project Owner</p>
        <p onClick={() => handleItemClick('Date Created')}><BsCalendar4Week /> Date Created</p>

        </div>
    </div>
  )
}

export default SortDrop