import React from 'react'
import './WebsiteDrop.scss'

const WebsiteDrop = ({ handleWebSelect, setOpenWeb }) => {

  const handleItemClick2 = (web) => {
    handleWebSelect(web)
    setOpenWeb(false)
  }
  return (
    <div className='direct-drop2'>
      <p onClick={() => handleItemClick2('Web')}>Web</p>
      <p onClick={() => handleItemClick2('Iphone')}>Iphone</p>
      <p onClick={() => handleItemClick2('Ipad')}>Ipad</p>
      <p onClick={() => handleItemClick2('Android')}>Android</p>
      <p onClick={() => handleItemClick2('Tablet')}>Tablet</p>
      <p onClick={() => handleItemClick2('Apple')}>Apple</p>
      <p onClick={() => handleItemClick2('Androidwear')}>Android wear</p>
      <p onClick={() => handleItemClick2('Costum')}>Costum</p>
    </div>
  )
}

export default WebsiteDrop