import React from 'react'
import './New.scss'
import { RiArrowUpSFill } from 'react-icons/ri'
import { useState } from 'react'
import NewProject from './NewProject/NewProject'
import NewTeam from './NewTeam/NewTeam'


const New = () => {
    const [newPro, setNewPro] = useState(false);
    const [showNewContainer, setShowNewContainer] = useState(true);
    const [newTeam, setNewTeam] = useState(false)
    const handleNew = (e) => {
        e.stopPropagation();
        setShowNewContainer(false);
        setNewPro(true);
    };

    const handleClose = () => {
        setNewPro(false);
    }
    const handleCloseTeam = () => {
        setNewTeam(false);
    }

    const handleTeam = (e) => {
        e.stopPropagation();
        setShowNewContainer(false);
        setNewTeam(!newTeam)
    }

    return (
        <>
            {showNewContainer && (
                <div className='new-container'>
                    <div className="new-inner flex-c">
                        <RiArrowUpSFill />
                        <div className="flex" onClick={(e) => { handleNew(e) }}>
                            <div className="new-icon ni1"></div>
                            <p>New Project</p>
                        </div>
                        <div className="flex" onClick={(e) => { handleTeam(e) }}>
                            <div className="new-icon ni2"></div>
                            <p>New Team</p>
                        </div>
                        <div className="flex">
                            <div className="new-icon ni3"></div>
                            <p>New Task</p>
                        </div>
                        <div className="flex">
                            <div className="new-icon ni4"></div>
                            <p>New Folder</p>
                        </div>
                        <div className="flex">
                            <div className="new-icon ni5"></div>
                            <p>Remote Upload</p>
                        </div>
                        <div className="flex">
                            <div className="new-icon ni6"></div>
                            <p>FTP Upload</p>
                        </div>
                    </div>
                </div>
            )}
            {newPro && <NewProject onClose={handleClose} />}
            {newTeam && <NewTeam onClose={handleCloseTeam} />}

        </>
    )
}

export default New;