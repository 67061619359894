import React from 'react'
import './NewTeam.scss'
import { AiOutlineCloseCircle, AiOutlineMenu } from 'react-icons/ai'
import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { ImMagicWand } from 'react-icons/im'
import { BsLink45Deg } from 'react-icons/bs'
import PrivateDropTeam from './PrivateDropTeam/PrivateDropTeam'
const NewTeam = ({ onClose }) => {

  const [showFirst, setFirst] = useState(true)
  const [teamName, setTeamName] = useState('')
  const [teamDescription, setTeamDescription] = useState('')
  const [isFormValid, setIsFormValid] = useState(false);
  const [selecetedProTm, setSelectedProTm] = useState(null)
  const [openPro, setOpenPro] = useState(false)

  const handleProTm = () => {
    setOpenPro(!openPro)
  }
  const handleProSelectTm = (pro) => {
    setSelectedProTm(pro);
    setOpenPro(false);
  };


  const handleClick3 = () => {
    onClose();
  };
  const handleClick = (e) => {
    e.stopPropagation();
  }

  const handleNextClick = (e) => {
    e.preventDefault();
    if (teamName === '') {
      alert('Please enter project name');
      setIsFormValid(false);
      return;
    }
    setFirst(false);
  }

  const handleCancelClick = (e) => {
    e.preventDefault();
    setFirst(true);
  }

  return (
    <div className='ntm-container' onClick={(e) => { handleClick(e) }} data-aos='zoom-in' data-aos-duration='400'>
      {showFirst ? (
        <form action="">
          <div className="npr-top flex-b">
            <p><FormattedMessage id='ntm-add' defaultMessage='Add New Team'></FormattedMessage></p>
            <AiOutlineCloseCircle onClick={handleClick3} />
          </div>

          <div className="npr-name">
            <p><FormattedMessage id='team-name' defaultMessage='Team Name'></FormattedMessage></p>
            <div className="npr-name-area flex">
              <div className="nprname-icon"></div>
              <input
                type="text"
                id='ptm-name'
                name='tm-name'
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                placeholder='Enter team name'
              />
            </div>
          </div>

          <div className="dp-projects tm-drp">
            <span>Type</span>
            <div className="dp-prj dp-inner flex-b" onClick={handleProTm}>
              <div className="dpprj-icon"></div>
              <p>{selecetedProTm || "Private Team"}</p>
              <MdKeyboardArrowDown />
              {openPro && <PrivateDropTeam
                handleProSelect={handleProSelectTm}
                setOpenPro={setOpenPro}
              ></PrivateDropTeam>}
            </div>
          </div>

          <div className="pr-description">
            <p><FormattedMessage id='pr-desc' defaultMessage='Description '></FormattedMessage><span>(optional)</span></p>
            <div className="tm-area">
              <AiOutlineMenu />
              <textarea
                id='description'
                name='description'
                value={teamDescription}
                onChange={(e) => setTeamDescription(e.target.value)}
                placeholder='Add team description '

              />
            </div>

          </div>

          <div className="tm-btm flex-b">
            <div className="exx flex">
              <ImMagicWand />
              <p>Use existing Team as template</p>
            </div>

            <div className="tm-btns flex">
              <button onClick={handleClick3} id='cancel'>Cancel</button>
              <button onClick={(e) => { handleNextClick(e) }} id='next'>Next</button>

            </div>
          </div>


        </form>
      ) : (
        <form action="">
          <div className="npr-top flex">
            <p><FormattedMessage id='ntm-add2' defaultMessage='Add People to *(new team name)*'></FormattedMessage></p>
            <AiOutlineCloseCircle onClick={handleClick3} />
          </div>

          <div className="tm-iner">
            <div className="tm-tx">
              <p>Type emails of people you want to add to your team. You can also invite others as guests.</p>
            </div>

            <div className="tm-emails">
              <div className="tm-titles flex-b">
                <p>Email or Username</p>
                <span>Skip this step</span>
              </div>

              <div className="tm-area-em">
                <div className="tmar-icon"></div>
                <textarea className='tm-txa' placeholder='Write emails here' />
              </div>
            </div>

            <div className="tm2-btm flex-b">
              <div className="exx flex">
                <BsLink45Deg />
                <p>Copy invite link</p>
              </div>

              <div className="tm-btns flex">
                <button onClick={(e) => { handleCancelClick(e) }} id='cancel'>Cancel</button>
                <button id='create'>Create Team</button>

              </div>
            </div>


          </div>


        </form>
      )}

    </div>
  )
}

export default NewTeam