import React from 'react'
import './UploadMenu.scss'
import { ReactComponent as Icon1 } from '../../../../../assets/Dashboard/Uploads/upload.svg'
import { ReactComponent as Icon2 } from '../../../../../assets/Dashboard/Uploads/laptop.svg'
import { ReactComponent as Icon3 } from '../../../../../assets/Dashboard/Uploads/noun_ftp_4307730.svg'
import { Link } from 'react-router-dom'


const uplData = [
    {
        id: 1,
        icon: <Icon1 />,
        title: 'Last Uploads',
        to: '/Dashboard/LastUploads'
    },
    {
        id: 2,
        icon: <Icon2 />,
        title: 'Remote Uploads',
        to: '/Dashboard/RemoteUploads'
    },
    {
        id: 3,
        icon: <Icon3 />,
        title: 'FTP Uploads',
        to: '/Dashboard/FTPUploads'
    },
]

const UploadMenu = ({
    isOpen = { isOpenNav: false, isOpenSideMenu: false },
    toggleSidebar,
}) => {
    const handleSidebarToggle = () => {
        toggleSidebar("sidebar");
    };
    return (
        <div className='uploadmenu'>
            {uplData.map((item) => {
                return (
                    <Link className='flex' to={item.to} key={item.id}>
                        <div className='upl-icon'>{item.icon}</div>
                        <p>{isOpen.isOpenSideMenu && <p>{item.title}</p>}</p>
                    </Link>
                )
            })}
        </div>
    )
}

export default UploadMenu