import React from 'react'
import './TeamType.scss'

const TeamType = ({ handleProSelect, setOpenPro }) => {

    const handleItemClick = (pro) => {
        handleProSelect(pro)
        setOpenPro(false)
    }
    return (
        <div className='direct-drop1'>
            <p onClick={() => handleItemClick('Private Team')}>Private Team</p>
            <p onClick={() => handleItemClick('Public Team')}>Public Team</p>
        </div>
    )
}

export default TeamType