import React, { useState, useEffect } from 'react'
import './PrjMenu.scss'
import { ReactComponent as Icon1 } from '../../../../../assets/Dashboard/PrjMenu/icon1.svg'
import { ReactComponent as Icon2 } from '../../../../../assets/Dashboard/PrjMenu/icon2.svg'
import { ReactComponent as Icon3 } from '../../../../../assets/Dashboard/PrjMenu/icon3.svg'
import { ReactComponent as Icon4 } from '../../../../../assets/Dashboard/PrjMenu/icon4.svg'
import { ReactComponent as Icon5 } from '../../../../../assets/Dashboard/PrjMenu/icon5.svg'
import { ReactComponent as Icon6 } from '../../../../../assets/Dashboard/PrjMenu/icon6.svg'
import { NavLink, useLocation } from 'react-router-dom';


const prjData = [
  {
    id: 1,
    icon: <Icon1 />,
    title: 'All Projects',
    to: '/Dashboard/Projects'
  },
  {
    id: 2,
    icon: <Icon2 />,
    title: 'Recently Added',
    to: ''
  },
  {
    id: 3,
    icon: <Icon3 />,
    title: 'Starred Projects',
    to: '/Dashboard/StarredProjects'
  },
  {
    id: 4,
    icon: <Icon4 />,
    title: 'On-going Projects',
    to: ''
  },
  {
    id: 5,
    icon: <Icon5 />,
    title: 'Shared Projects',
    to: ''
  },
  {
    id: 6,
    icon: <Icon6 />,
    title: 'File Manager',
    to: ''
  },
]

const PrjMenu = ({ isOpen = { isOpenNav: false, isOpenSideMenu: false }, toggleSidebar }) => {
  const handleSidebarToggle = () => {
    toggleSidebar('sidebar');
  };
  const [activeLinkId, setActiveLinkId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const activeLinkItem = prjData.find((item) => item.to === currentPath);
    if (activeLinkItem) {
      setActiveLinkId(activeLinkItem.id);
    } else {
      setActiveLinkId(null);
    }
  }, [location]);


  return (
    <div className="projectmenu">
      {prjData.map((item) => (
        <NavLink
          className={`flex ${item.id === activeLinkId ? 'active-link' : ''}`}
          to={item.to}
          key={item.id}
          onClick={() => setActiveLinkId(item.id)}
        >
          <div className="upl-icon">{item.icon}</div>
          {isOpen.isOpenSideMenu && <>{item.title}</>}
        </NavLink>
      ))}
    </div>
  );
};

export default PrjMenu;