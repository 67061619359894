import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { BiFilterAlt } from 'react-icons/bi'
import { RiUser3Line } from 'react-icons/ri'
import { MdKeyboardArrowDown, MdOutlineSort } from 'react-icons/md'
import { BsGridFill } from 'react-icons/bs'
import { FaThList } from 'react-icons/fa'
import './OvPage.scss'
import ProjectsSlider from './ProjectsSlider/ProjectsSlider'
import Ongoing from './Ongoing/Ongoing'
import Completed from './Completed/Completed'
import Comments from './Comments/Comments'
import Select from 'react-select';
import Activity from './Activity/Activity'
import Teams from './Teams/Teams'
import Tasks from './Tasks/Tasks'
import Guides from './Guides/Guides'
import TeamDrop from './TeamDrop/TeamDrop'
import SortDrop from './SortDrop/SortDrop'

const OvPage = ({ projects }) => {
  const [openTeam, setOpenTeam] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState(null);

  const [openSort, setOpenSort] = useState(false)
  const [selectedSort, setSelectedSort] = useState(null);

  const handleTeam = () => {
    setOpenTeam(!openTeam);
  }
  const handleTeamSelect = (team) => {
    setSelectedTeam(team);
    setOpenTeam(false);
  };

  const handleSort = () => {
    setOpenSort(!openSort);
  }
  const handleSortSelect = (type) => {
    setSelectedSort(type);
    setOpenSort(false);
  };
  return (
    <div className='ov-page'>

      <div className="ov-top flex-b">
        <div className="ov-topp-inner flex-b">
          <div className="ov-left flex">
            <p><FormattedMessage id='ov-tx1' defaultMessage='Recently Updated Projects'></FormattedMessage></p>
            <Link to='/Dashboard/Projects'><FormattedMessage id='ov-link1' defaultMessage='View All Projects'></FormattedMessage></Link>
          </div>
          <div className="ov-right flex">
            <BiFilterAlt id='filter' />
            <div className="ov-team flex-b" onClick={handleTeam}>
              <RiUser3Line id='user' />
              <p><FormattedMessage id='team' defaultMessage='Team:'></FormattedMessage></p>
              <p id='p-sel'>{selectedTeam || 'HostDesign Team'}</p>
              <MdKeyboardArrowDown />
              {openTeam &&
                (<TeamDrop
                  handleTeamSelect={handleTeamSelect}
                  setOpenTeam={setOpenTeam}
                ></TeamDrop>)
              }
            </div>

            <div className="ov-filter flex-b" onClick={handleSort}>
              <MdOutlineSort id='filterr' />
              <p id='p-sel'>{selectedSort || 'Sort by'}</p>
              <MdKeyboardArrowDown />
              {openSort &&
                (<SortDrop
                  handleSortSelect={handleSortSelect}
                  setOpenSort={setOpenSort}
                ></SortDrop>)
              }
            </div>

            <div className="ov-menu flex">
              <button className="ov-grid flex">
                <BsGridFill />
              </button>

              <button className="ov-list flex">
                <FaThList />
              </button>
            </div>
          </div>
        </div>


      </div>

      <div className="ov-sld">
        <ProjectsSlider projects={projects} />
      </div>

      <div className="ov-main flex-b">
        <div className="ov-main-left">
          <Ongoing projects={projects} />
          <Completed projects={projects} />
        </div>
        <div className="ov-main-right">
          <Comments />
          <Activity />
        </div>
      </div>
      <div className="ov-main2">
        <div className="ov-m2 flex">
          <Teams />
          <Tasks />
        </div>
        <Guides />
      </div>
    </div>
  )
}

export default OvPage