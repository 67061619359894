import React from 'react'
import './Activity.scss'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { BsGridFill, BsInfoCircle } from 'react-icons/bs'
import { FiActivity } from 'react-icons/fi'
import { GoCalendar } from 'react-icons/go'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import CalendarHeatmap from 'react-calendar-heatmap';

const Activity = () => {
    return (
        <div className='ov-activity'>
            <div className="act-top flex-b">
                <div className="act-left flex">
                    <p><FormattedMessage id='act-t' defaultMessage='Activity'></FormattedMessage></p>
                    <Link to='/'><FormattedMessage id='act-link' defaultMessage='Show Profile'></FormattedMessage></Link>
                </div>

                <div className="act-right flex">
                    <div className="ov-menu flex">
                        <button className="ov-grid flex">
                            <BsGridFill />
                        </button>

                        <button className="ov-list flex">
                            <FiActivity />
                        </button>
                    </div>
                    <div className="act-filter flex-b">
                        <GoCalendar />
                        <p>Last 6 months</p>
                        <MdKeyboardArrowDown />
                    </div>
                </div>

            </div>


            <div className="activity-area">
                <div className="activity-area">
                    <CalendarHeatmap
                        startDate={new Date('2023-01-01')}
                        endDate={new Date('2023-06-31')}
                        weekdayLabels={['', 'Mon', '', 'Wed', '', 'Fri', '']}
                        values={[
                            { date: '2023-01-01', count: 12 },
                            { date: '2023-01-22', count: 122 },
                            { date: '2023-01-30', count: 38 },
                            { date: '2023-02-01', count: 12 },
                            { date: '2023-02-22', count: 122 },
                            { date: '2023-02-30', count: 38 },
                            { date: '2023-05-01', count: 12 },
                            { date: '2023-05-22', count: 122 },
                            { date: '2023-05-30', count: 38 },

                        ]}
                        colors={['#EFEFEF', '#A1CAF1', '#55ABFB', '#0084FF', '#0862B5']}

                    />
                </div>

                <div className="activity-exp flex">
                    <p>Less</p>
                    <div className="act-color col1"></div>
                    <div className="act-color col2"></div>
                    <div className="act-color col3"></div>
                    <div className="act-color col4"></div>
                    <div className="act-color col5"></div>
                    <p>More</p>
                    <BsInfoCircle />
                </div>

            </div>
        </div>
    )
}

export default Activity