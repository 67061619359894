import React, { useState } from 'react'
import './NewProject.scss'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { colors, icons } from './data'
import { AiOutlinePlusCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import NextNew from './NextNew'
import DirectUpload from './DirectUpload'
import axios from 'axios'
import { getToken } from '../../../../../components/pages/Auth/Token'
import apiUrl from '../../../../../api'
const NewProject = ({ onClose }) => {
    const [project_title, setPrName] = useState('');
    const [project_description, setDescription] = useState('');
    const [background_color, setSelectedColorHex] = useState(null);
    const [icon_id, setSelectedIcon] = useState(null);
    const [projectdevice, setProjectDevice] = useState('web');
    const [showFirstForm, setShowFirstForm] = useState(true);
    const [showDirectUploadForm, setShowDirectUploadForm] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [privacy, setPrivacy] = useState(1111)
    const [projecttype, setProjectType] = useState('private');


    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = getToken();
        try {
            const response = await axios.post(apiUrl + 'user/projects', {
                project_title,
                projecttype,
                project_description,
                background_color,
                icon_id,
                privacy,
                projectdevice
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const createdProject = response.data;
            const projectId = createdProject.id;
            const projectTitle = createdProject.project_title;
            // alert('Project successfully created')
            window.location.href = `/Dashboard/Screens/${projectId}/${projectTitle}`

        } catch (error) {
            console.log(error)
        }
    }



    const handleNextClick = (e) => {
        e.preventDefault();
        if (project_title === '') {
            alert('Please enter project name');
            setIsFormValid(false);
            return;
        }
        setShowFirstForm(false);
    }

    const handleDirectUploadClick = (e) => {
        e.preventDefault()
        setShowFirstForm(false);
        setShowDirectUploadForm(true);
    }
    const handleProjectType = (type) => {
        setProjectType(type);
    };
    const handleClick = (e) => {
        e.stopPropagation();
    }
    const handleColorClick = (hex) => {
        setSelectedColorHex(hex);
    };

    const handleIconClick = (icon) => {
        setSelectedIcon(icon);
    };
    const handleClick2 = () => {
        onClose();
    };



    return (
        <>
            <div className='new-project-box' data-aos='zoom-in' data-aos-duration='400' onClick={(e) => { handleClick(e) }}>
                {showFirstForm ? (
                    <form onSubmit={handleSubmit}>
                        <div className="npr-top flex">
                            <p><FormattedMessage id='npr-add' defaultMessage='Add New Project'></FormattedMessage></p>
                            <p onClick={handleDirectUploadClick} id='npr2'><FormattedMessage id='npr-direct' defaultMessage='Direct Upload'></FormattedMessage></p>
                            <AiOutlineCloseCircle onClick={handleClick2} />
                        </div>

                        <div className="npr-name">
                            <p><FormattedMessage id='project-name' defaultMessage='Project name'></FormattedMessage></p>
                            <div className="npr-name-area flex">
                                <div className="nprname-icon"></div>
                                <input
                                    type="text"
                                    id='pr-name'
                                    name='pr-name'
                                    value={project_title}
                                    onChange={(e) => setPrName(e.target.value)}
                                    placeholder='Write project name here'
                                />
                            </div>
                        </div>

                        <div className="pr-type flex">
                            <div className={`pr-private prtype flex ${projecttype === 'private' ? 'active' : ''}`} onClick={() => { handleProjectType('private') }}>
                                <div className="prtype-icon prt-icon1"></div>
                                <div className="prt-content">
                                    <h4>Private</h4>
                                    <p>Only people you invite can see this project. You can choose who can edit.</p>
                                </div>
                            </div>
                            <div className={`pr-public prtype flex ${projecttype === 'public' ? 'active' : ''}`} onClick={() => { handleProjectType('public') }}>
                                <div className="prtype-icon prt-icon2"></div>
                                <div className="prt-content">
                                    <h4>Public</h4>
                                    <p>Anyone on the internet can see this project.</p>
                                </div>
                            </div>
                        </div>


                        <div className="npr-details flex">
                            <div className="pr-colors">
                                <p><FormattedMessage id='color' defaultMessage="Color"></FormattedMessage></p>
                                <div className="colors flex">
                                    {colors.map((c) => (
                                        <div
                                            key={c.id}
                                            className='pr-color'
                                            style={{ background: `${c.color}` }}
                                            onClick={() => handleColorClick(c.color)}
                                        >
                                            <div className="inner-pr-color"
                                                style={{
                                                    border: `1.5px solid ${c.color === background_color ? '#4280EB' : 'transparent'}`,
                                                }}
                                            ></div>
                                        </div>
                                    ))}
                                    <AiOutlinePlusCircle />
                                </div>
                            </div>

                            <div className="pr-icons ">
                                <p><FormattedMessage id='icon' defaultMessage="Icon"></FormattedMessage></p>
                                <div className="pri-icons flex">
                                    {icons.map((i) => {
                                        return (
                                            <div
                                                key={i.id}
                                                className='pr-icon'
                                                onClick={() => handleIconClick(i)}
                                                style={{
                                                    content: `url(${i.icon})`,
                                                    borderBottom: `1.5px solid ${i === icon_id ? '#4280EB' : 'transparent'}`,

                                                }}
                                            >
                                            </div>
                                        )
                                    })}
                                    <AiOutlinePlusCircle />

                                </div>
                            </div>
                        </div>


                        <div className="pr-description">
                            <p><FormattedMessage id='pr-desc' defaultMessage='Description '></FormattedMessage><span>(optional)</span></p>
                            <textarea
                                id='description'
                                name='description'
                                value={project_description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder='Add project description '
                            />
                        </div>

                        <div className="npr-bottom flex">
                            <button id='pr-cancel' onClick={handleClick2}>Cancel</button>
                            <button id='pr-next' onClick={handleNextClick}>Next</button>
                        </div>

                    </form>
                )
                    : (
                        showDirectUploadForm ? (
                            <DirectUpload
                                handleClick2={handleClick2}
                                setShowFirstForm={setShowFirstForm}
                                setShowDirectUploadForm={setShowDirectUploadForm}
                            />
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <NextNew
                                    handleClick2={handleClick2}
                                    setShowFirstForm={setShowFirstForm}
                                />
                            </form>
                        )

                    )
                }
            </div>
        </>
    )
}

export default NewProject