import React from 'react'
import './Completed.scss'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { BiSearch, BiFilterAlt } from 'react-icons/bi'
import { MdOutlineSort, MdKeyboardArrowDown, MdOutlineMoreHoriz, MdContentCopy } from 'react-icons/md'
const Completed = ({ projects }) => {

    const formatDate = (dateString) => {
        const options = {
            day: 'numeric',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };

        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    };


    const completed = projects?.filter((p) => p.finished === "true");

    return (
        <div className='completed-container'>
            <div className="cmp-inner">
                <div className="ong-top flex-b">
                    <div className="ongtop-left flex">
                        <p><FormattedMessage id='ong-t' defaultMessage='Completed Projects'></FormattedMessage></p>
                        <div className="ong-nr flex">
                            {projects?.length}
                        </div>
                        <Link to='/'><FormattedMessage id='ong-link' defaultMessage='View Completed Projects'></FormattedMessage></Link>
                    </div>

                    <div className="ongtop-right flex">
                        <BiSearch />
                        <BiFilterAlt />
                        <div className="ov-filter flex-b">
                            <MdOutlineSort />
                            <p>Sort by</p>
                            <MdKeyboardArrowDown />
                        </div>
                    </div>

                </div>

                <div className="ongoing-titles flex-b">
                    <div className="ong-name flex">
                        <p>Name</p>
                        <div className="ong-arrow"></div>
                    </div>
                    <div className="ong-people flex">
                        <p>Marked as Completed</p>
                    </div>
                    <div className="ong-screens flex">
                        <p>People</p>
                    </div>
                    <div className="ong-blank flex"></div>
                </div>

                <div className="ongoing-items">
                    {completed?.map((o) => {
                        const formattedDate = formatDate(o.completed_date);

                        return (
                            <div key={o.id} className='ongoing-item flex-b'>
                                <div className="ong-namee flex">
                                    <div className="ong-icon" style={{ content: `url(${o.icon})` }}></div>
                                    <div className="ong-info">
                                        <p>{o.project_title}</p>
                                        <div className="comp-info flex">
                                            <span className='flex'>hostdesign.com/88akcnas...</span>
                                            <button className='copy flex'>
                                                <MdContentCopy />
                                                Copy
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="comp-marked flex">
                                    <p>{formattedDate}</p>
                                </div>
                                <div className="ong-pp flex">
                                    <div className="ong-profile1"></div>
                                    <div className="ong-profile2"></div>
                                    <div className="ong-profile3"></div>
                                </div>

                                <div className="ong-blankk flex">
                                    <div className="comp-ch"></div>
                                    <MdOutlineMoreHoriz />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>





        </div>
    )
}

export default Completed