import React, { useState, useRef , useEffect} from "react";
import "../../../shared/DashSidebar/DashSidebar.scss";
import { mainData } from "../../../shared/DashSidebar/data";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import Menu2 from "../../../shared/Menu2/Menu2";
import { GiQueenCrown } from "react-icons/gi";
import { sideData } from "../data";
import { Link } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import ThemeSwitcher from "../../../ThemeSwitcher";
import UploadMenu from "./UploadMenu/UploadMenu";
import PrjMenu from "./PrjMenu/PrjMenu";
import { useLocation } from "react-router-dom";
const SideMenu = ({
  isOpen = { isOpenNav: false, isOpenSideMenu: false },
  toggleSidebar,
  setNewTeam,
  newTeam,
  height
}) => {
  const [showProjectsSubMenu, setShowProjectsSubMenu] = useState(
    localStorage.getItem('showProjectsSubMenu') === 'true'
  );

  const [showUploadsSubMenu, setShowUploadsSubMenu] = useState(false);
  const location = useLocation();

  const sideMenuRef = useRef(null);
  const [startX, setStartX] = useState(null);
  const [currentX, setCurrentX] = useState(null);

  useEffect(() => {
    localStorage.setItem('showProjectsSubMenu', showProjectsSubMenu);
  }, [showProjectsSubMenu]);

  const handleProjectsClick = () => {
    setShowProjectsSubMenu((prev) => !prev);
  };

  const handleSidebarToggle = () => {
    toggleSidebar("sidebar");
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setCurrentX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (startX) {
      setCurrentX(e.touches[0].clientX);
    }
  };

  const handleTouchEnd = () => {
    if (startX && currentX) {
      const difference = startX - currentX;
      const threshold = 100;

      if (difference > threshold) {
        sideMenuRef.current.classList.remove('open');
      } else if (difference < -threshold) {
        sideMenuRef.current.classList.add('open');
      }
    }
    setStartX(null);
    setCurrentX(null);
  };

  const handleUploadsClick = () => {
    setShowUploadsSubMenu(!showUploadsSubMenu);
    setShowProjectsSubMenu(false);
  };

  let activeLinkId = null;

  if (location.pathname === '/Dashboard/Overview') {
    activeLinkId = 1;
  } else if (location.pathname === '/Dashboard/Projects') {
    activeLinkId = 2;
  } else if (location.pathname === '/Dashboard/Uploads') {
    activeLinkId = 3;
  } else if (location.pathname === '/Dashboard/Comments') {
    activeLinkId = 4;
  } else if (location.pathname === '/Dashboard/Shares') {
    activeLinkId = 5;
  }







  return (
    <div
      ref={sideMenuRef}
      className="dash-sidebar"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{
        width: isOpen.isOpenSideMenu
          ? isOpen.isOpenNav
            ? "320px"
            : "0"
          : isOpen.isOpenNav
            ? "95px"
            : "0",
        border: isOpen.isOpenSideMenu ? isOpen.isOpenNav ? "" : "none" : "none",
        paddingBottom: isOpen.isOpenNav ? "10%" : "0",

      }}
    >
      <div className="inner-container">
        <div className="inner-dside">
          <div className="close-side flex">
            <button
              className="flex clsbtn"
              onClick={handleSidebarToggle}
              style={{
                backgroundColor: isOpen ? "#fafafa" : "transparent",
                top: isOpen.isOpenSideMenu ? "40%" : "40%",
                left: isOpen.isOpenNav === false ? "-50px" : "",
              }}
            >
              {isOpen.isOpenSideMenu ? (
                <div className="s-arr flex">
                  <RiArrowLeftSLine />
                </div>
              ) : (
                <div className="s-arr flex">
                  <RiArrowRightSLine />
                </div>
              )}
            </button>
          </div>

          {isOpen.isOpenNav && (
            <div className="menu1">
              <div className="s-tb-list flex-c">
                {mainData.map((m) => (
                  <Link key={m.id} to={m.to} className={`main-tab ${m.id === activeLinkId ? 'active-link' : ''}`}>
                    <div className="main-tab2">
                      {m.id === 2 ? (
                        <div
                          className={`main-tab2 flex ${showProjectsSubMenu ? 'selectedd' : ''}`}
                          onClick={handleProjectsClick}
                        >
                          <div className="tab-iconn flex">
                            <div className="tab-icon" style={{ content: `url(${m.icon})` }}></div>
                          </div>
                          <p>{isOpen.isOpenSideMenu && <p>{m.title}</p>}</p>
                          {isOpen.isOpenSideMenu && <MdKeyboardArrowDown
                            className={`arrow-icon ${showProjectsSubMenu ? 'expanded' : ''}`}
                          />}

                        </div>
                      ) : m.id === 3 ? (
                        <div
                          className={`main-tab2 flex ${showUploadsSubMenu ? 'selectedd' : ''}`}
                          onClick={handleUploadsClick}
                        >
                          <div className="tab-iconn flex">
                            <div className="tab-icon" style={{ content: `url(${m.icon})` }}></div>
                          </div>
                          <p>{isOpen.isOpenSideMenu && <p>{m.title}</p>}</p>
                          {isOpen.isOpenSideMenu && <MdKeyboardArrowDown
                            className={`arrow-icon ${showUploadsSubMenu ? 'expanded' : ''}`}
                          />}

                        </div>
                      ) : (
                        <div className="main-tab2">
                          <div className="tab-iconn flex">
                            <div className="tab-icon" style={{ content: `url(${m.icon})` }}></div>
                          </div>
                          <p>{isOpen.isOpenSideMenu && <p>{m.title}</p>}</p>
                        </div>
                      )}
                    </div>

                    {m.id === 2 && isOpen.isOpenNav && showProjectsSubMenu && < PrjMenu
                      toggleSidebar={toggleSidebar}
                      isOpen={isOpen}
                    />}
                    {m.id === 3 && showUploadsSubMenu && <UploadMenu
                      toggleSidebar={toggleSidebar}
                      isOpen={isOpen}
                    />}
                  </Link>
                ))}
              </div>
            </div>
          )}

          {isOpen.isOpenNav && (
            <>
              <div className="menu2"></div>
              <Menu2
                isOpen={isOpen}
                setNewTeam={setNewTeam}
                newTeam={newTeam}
              />
            </>
          )}
        </div>
        {isOpen.isOpenNav && (
          <>
            {isOpen.isOpenSideMenu && (
              <div className="btm-side flex-c">
                <div className="upgrade flex-c">
                  <div className="c1"></div>
                  <div className="c2"></div>
                  <div className="c3"></div>
                  <div className="c4"></div>
                  <div className="c5"></div>
                  <div className="c6"></div>
                  <h4>Bring your ideas to life, faster!</h4>
                  <button className="flex">
                    Upgrade to HostDesign Pro
                    <GiQueenCrown />
                  </button>
                  <div className="b2-items flex-c">
                    {sideData.map((s) => {
                      return (
                        <div className="b2-item flex" key={s.id}>
                          <div
                            className="b2-icon"
                            style={{ content: `url(${s.icon})` }}
                          ></div>
                          <p>{s.title}</p>
                        </div>
                      );
                    })}
                  </div>

                  <Link to="/Dashboard/Upgrade" className="flex">
                    <GiQueenCrown />
                    Upgrade
                  </Link>
                </div>

                <div className="side-links flex">
                  <Link to="/">Help</Link>
                  <Link to="/Dashboard/Upgrade">Upgrade</Link>
                  <Link to="/">FAQs</Link>
                  <Link to="/">Apps</Link>
                  <Link to="/">
                    Other <MdKeyboardArrowDown />
                  </Link>
                </div>
                <div className="side-infos flex">
                  <p id="p-1">
                    © 2023 <Link to="/">Hostdesign</Link> Inc.
                  </p>
                  <p> All rights reserved.</p>
                </div>

                <div className="side-switch">
                  <ThemeSwitcher />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SideMenu;
