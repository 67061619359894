import React, { useEffect, useState, useRef } from 'react'
import Nav from '../../shared/Nav/Nav'
import './Overview.scss'
import '../../shared/DashSidebar/DashSidebar.scss'
import OvPage from './OvPage/OvPage'
import SideMenu from './SideMenu/SideMenu'
import HelmetComponent from '../../../components/shared/HelmetComponent/HelmetComponent'
import axios from 'axios'
import { getToken } from '../../../components/pages/Auth/Token'
import NewTeam from '../../shared/Menu2/NewTeam/NewTeam'
import apiUrl from '../../../api'
const Overview = () => {


  const initialState = { isOpenNav: true, isOpenSideMenu: true };
  const [isOpen, setIsOpen] = useState(initialState);
  const [newTeam, setNewTeam] = useState(false)
  const dashboardRef = useRef(null);

  useEffect(() => {
    const savedState = localStorage.getItem('sidebarState');
    if (savedState) {
      setIsOpen(JSON.parse(savedState));
    }
  }, []);

  const toggleSidebar = (source) => {
    setIsOpen((prevOpen) => {
      if (source === 'nav') {
        const updatedState = { ...prevOpen, isOpenNav: !prevOpen.isOpenNav };
        localStorage.setItem('sidebarState', JSON.stringify(updatedState));
        return updatedState;
      } else if (source === 'sidebar') {
        const updatedState = { ...prevOpen, isOpenSideMenu: !prevOpen.isOpenSideMenu };
        localStorage.setItem('sidebarState', JSON.stringify(updatedState));
        return updatedState;
      }
      return prevOpen;
    });
  };

  const [projects, setProjects] = useState(null);

  const fetchData = async () => {
    try {
      let savedProjects = JSON.parse(localStorage.getItem('overviewProjects'));
      if (!savedProjects) {
        const response = await axios.get(apiUrl + 'user/projects?filter=true', {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        });
        savedProjects = response.data;
        localStorage.setItem('overviewProjects', JSON.stringify(savedProjects));
      }

      const filteredProjects = savedProjects?.data
      setProjects(filteredProjects);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      localStorage.removeItem('overviewProjects');
    };
  }, []);

  return (
    <div className='dashboard-page' ref={dashboardRef}>
      <HelmetComponent title='HostDesign - Overview'></HelmetComponent>
      <Nav projects={projects}
        toggleSidebar={toggleSidebar}
        isOpen={isOpen}
      />
      <div className="ov-main1 flex">
        <SideMenu
          toggleSidebar={toggleSidebar}
          isOpen={isOpen}
          setNewTeam={setNewTeam}
          newTeam={newTeam}
          setIsOpen={setIsOpen}
         
        />
        <OvPage projects={projects} />
      </div>

      {newTeam && <NewTeam
        setNewTeam={setNewTeam}
        newTeam={newTeam}
      />}
    </div>
  )
}

export default Overview