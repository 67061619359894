import { FormattedMessage } from 'react-intl'
import icon1 from '../../../assets/Dashboard/Overview/Sidebar/t1.png'
import icon2 from '../../../assets/Dashboard/Overview/Sidebar/t2.png'
import icon3 from '../../../assets/Dashboard/Overview/Sidebar/t3.png'
import icon4 from '../../../assets/Dashboard/Overview/Sidebar/t4.png'
import icon5 from '../../../assets/Dashboard/Overview/Sidebar/t5.png'
import icon6 from '../../../assets/Dashboard/Overview/Sidebar/t6.png'
import icon7 from '../../../assets/Dashboard/Overview/Sidebar/t7.png'
import doc from '../../../assets/Dashboard/Overview/Sidebar/layers-fill.png'
import { HiUsers } from 'react-icons/hi'


import ico1 from '../../../assets/Dashboard/Overview/Ovpage/Teams/ico1.png'
import ico2 from '../../../assets/Dashboard/Overview/Ovpage/Teams/ico2.png'
import ico3 from '../../../assets/Dashboard/Overview/Ovpage/Teams/ico3.png'
import ico4 from '../../../assets/Dashboard/Overview/Ovpage/Teams/ico4.png'
import ico5 from '../../../assets/Dashboard/Overview/Ovpage/Teams/ico5.png'
import ico6 from '../../../assets/Dashboard/Overview/Ovpage/Teams/ico6.png'
import ico7 from '../../../assets/Dashboard/Overview/Ovpage/Teams/ico7.png'



export const teamData2 = [
    {
        id: 1,
        icon: ico1,
        title: <FormattedMessage id='tdr-t1' defaultMessage='Open Team'></FormattedMessage>,
        to: '/'
    },
    {
        id: 2,
        icon: ico2,
        title: <FormattedMessage id='tdr-t2' defaultMessage='View All Projects'></FormattedMessage>,
        to: '/'
    },
    {
        id: 3,
        icon: ico3,
        title: <FormattedMessage id='tdr-t3' defaultMessage='Pin to sidebar'></FormattedMessage>,
        to: '/'
    },
    {
        id: 4,
        icon: ico4,
        title: <FormattedMessage id='tdr-t4' defaultMessage='Add People'></FormattedMessage>,
        to: '/'
    },
    {
        id: 5,
        icon: ico5,
        title: <FormattedMessage id='tdr-t5' defaultMessage='Add New Project'></FormattedMessage>,
        to: '/'
    },
    {
        id: 6,
        icon: ico6,
        title: <FormattedMessage id='tdr-t6' defaultMessage='Hide'></FormattedMessage>,
        to: '/'
    },
    {
        id: 7,
        icon: ico7,
        title: <FormattedMessage id='tdr-t7' defaultMessage='Leave Team'></FormattedMessage>,
        to: '/'
    },
]


export const menuData = [
    {
        id: 1,
        icon: icon1,
        title: <FormattedMessage id='menu2-t1' defaultMessage='Personal'></FormattedMessage>,
        miniIcon: doc,
        nr: '43',
        hover: <FormattedMessage id='hv-1' defaultMessage='Projects'></FormattedMessage>,
        to: '/Dashboard/AccountSettings'
    },
    {
        id: 2,
        icon: icon2,
        title: <FormattedMessage id='menu2-t2' defaultMessage='Perfect Team'></FormattedMessage>,
        miniIcon: <HiUsers />,
        nr: '11',
        hover: <FormattedMessage id='hv-2' defaultMessage='Members'></FormattedMessage>,
        to: ''
    },
    {
        id: 3,
        icon: icon3,
        title: <FormattedMessage id='menu2-t3' defaultMessage='Graphic Designers'></FormattedMessage>,
        miniIcon: <HiUsers />,
        nr: '10',
        hover: <FormattedMessage id='hv-2' defaultMessage='Members'></FormattedMessage>,
        to: ''
    },
    {
        id: 4,
        icon: icon4,
        title: <FormattedMessage id='menu2-t4' defaultMessage='HostDesign Team'></FormattedMessage>,
        miniIcon: <HiUsers />,
        nr: '51',
        hover: <FormattedMessage id='hv-2' defaultMessage='Members'></FormattedMessage>,
        to: ''
    },
    {
        id: 5,
        icon: icon5,
        title: <FormattedMessage id='menu2-t5' defaultMessage='Epic Ideas'></FormattedMessage>,
        miniIcon: <HiUsers />,
        nr: '37',
        hover: <FormattedMessage id='hv-2' defaultMessage='Members'></FormattedMessage>,
        to: ''
    },
    {
        id: 6,
        icon: icon6,
        title: <FormattedMessage id='menu2-t6' defaultMessage='We Design'></FormattedMessage>,
        miniIcon: <HiUsers />,
        nr: '6',
        hover: <FormattedMessage id='hv-2' defaultMessage='Members'></FormattedMessage>,
        to: ''
    },
    {
        id: 7,
        icon: icon7,
        title: <FormattedMessage id='menu2-t7' defaultMessage='House Of Designs'></FormattedMessage>,
        miniIcon: <HiUsers />,
        nr: '29',
        hover: <FormattedMessage id='hv-2' defaultMessage='Members'></FormattedMessage>,
        to: ''
    },
]