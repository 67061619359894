import { FormattedMessage } from 'react-intl'
import icon1 from '../../../../assets/Dashboard/Overview/Nav/icon1.png'
import icon2 from '../../../../assets/Dashboard/Overview/Nav/icon2.png'
import icon3 from '../../../../assets/Dashboard/Overview/Nav/icon3.png'
import icon4 from '../../../../assets/Dashboard/Overview/Nav/icon4.png'
import icon5 from '../../../../assets/Dashboard/Overview/Nav/icon5.png'
import icon6 from '../../../../assets/Dashboard/Overview/Nav/icon6.png'
import icon7 from '../../../../assets/Dashboard/Overview/Nav/icon7.png'


export const userData = [
    {
        id:1,
        icon:icon1,
        to:'/Dashboard/AccountSettings',
        text:<FormattedMessage id='ut-1' defaultMessage='Your Profile'></FormattedMessage>,
    },
    {
        id:2,
        icon:icon2,
        to:'/Dashboard/Comments',
        text:<FormattedMessage id='ut-2' defaultMessage='Messages'></FormattedMessage>,
    },
    {
        id:3,
        icon:icon3,
        to:'',
        text:<FormattedMessage id='ut-3' defaultMessage='Teams'></FormattedMessage>,
    },
    {
        id:4,
        icon:icon4,
        to:'',
        text:<FormattedMessage id='ut-4' defaultMessage='Activity'></FormattedMessage>,
    },
    {
        id:5,
        icon:icon5,
        to:'',
        text:<FormattedMessage id='ut-5' defaultMessage='Billing'></FormattedMessage>,
    },
    {
        id:6,
        icon:icon6,
        to:'/Dashboard/AccountSettings',
        text:<FormattedMessage id='ut-6' defaultMessage='Account Settings'></FormattedMessage>,
    },
    {
        id:7,
        icon:icon7,
        to:'',
        text:<FormattedMessage id='ut-7' defaultMessage='Get Help'></FormattedMessage>,
    },
]